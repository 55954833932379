import update from 'immutability-helper';
import { createActions } from 'redux-actions';
import { csvStringToIntArray } from '../../../common/helpers';
import { I } from '../../../common/v5/config';
import {
	deleteReviewAddress,
	deleteReviewAgent,
	getAgentData,
	getReviewAddresses,
	getReviewAgents,
	postOneReviewAddress,
	postReviewAddress,
	postReviewByAgent,
	postReviewAddressesFile,
	postTemplates,
	updateAgentData,
	getAgentList,
	getAgentNew,
	activateAgent,
	deactivateAgent,
	unlockAgent,
	getAgentSalutations,
	getAgentSignatures,
	getOneReviewAddress,
	getOneTemplate,
	getOneTemplateDelete,
	getTemplates,
	addAgentMP,
	removeAgentMP,
	addAgentAvatar,
	removeAgentAvatar,
	getAgentValidateExternalID,
	getAgentWordList,
	loadAgentWordList,
	saveAgentWordList,
	removeAgentWordlist,
	getSignatures,
	getSalutations,
	getOneSignature,
	getOneSalutation,
	getOneErrandInternalState,
	postErrandInternalState,
	postSignatures,
	postSalutations,
	deleteSignature,
	deleteSalutation,
	getAddressbookList,
	getAdminErrandStateList,
	editAddressbook,
	saveAddressbook,
	removeAddressbook,
	uploadAddressbook,
	getExternalExpertAddressList,
	saveExternalExpertAddressList,
	deleteExternalExpertAddressList,
	removeEEAddressAvatar,
	uploadAgentList,
	uploadAutoTagList,
	checkAgentImportStatus,
	validateAgentLoginName,
	getFileArchive,
	editFileArchive,
	saveFileArchive,
	deleteFileArchive,
	getOneFileArchive,
	downloadFileArchive,
	getGenerativeAIDocument,
	postGenerativeAIDocument,
	deleteGenerativeAIDocument,
	getRouteKeywords,
	deleteRouteKeywords,
	postRouteKeywords,
	getAccount,
	getOneAccount,
	postAccount,
	deleteAccount,
	postAccountSlack,
	deleteAccountSlack,
	getOneAccountTwilio,
	postAccountTwilio,
	deleteAccountTwilio,
	getOneAccountSMPP,
	deleteAccountSMPP,
	getAccountFacebook,
	getOneAccountFacebook,
	postAccountFacebook,
	deleteAccountFacebook,
	getOneRouteKeywords,
	getCorsWhitelist,
	getOneCorsWhitelist,
	postCorsWhitelist,
	deleteCorsWhitelist,
	getChatIPAddressBlacklist,
	deleteChatIPAddressBlacklist,
	getCallRecordings,
	getOneCallRecordings,
	getAgentSipLogins,
	getOneAgentSipLogin,
	postSaveAgentSipLogin,
	deleteAgentSipLogin,
	getSipPriorityNumbers,
	getSipOnePrioNumber,
	postSaveSipPrioNumber,
	deleteSipPrioNumber,
	getContactCard,
	getOneContactCard,
	deleteContactCardAccount,
	getContactCardNotes,
	deleteContactCardNote,
	postContactCard,
	postMergeContactCard,
	deleteContactCard,
	postCustomerDelAvatar,
	getRoutingGroups,
	getOneRoutingGroups,
	postRoutingGroups,
	deleteRoutingGroups,
	getRouteAutoTags,
	getOneRouteAutoTags,
	postRouteAutoTags,
	deleteRouteAutoTags,
	getRouteSip,
	getOneRouteSip,
	postRouteSip,
	deleteRouteSip,
	getStunTurnList,
	saveStunTurn,
	getCompanyList,
	getConfigChangeLog,
	saveNewCompany,
	removeCompany,
	saveNewChatWidgetConfig,
	saveNewFaqWidgetConfig,
	saveNewVoiceWidgetConfig,
	getChatWidgetCfgList,
	getChatWidgetCfgDefList,
	getFaqWidgetCfgList,
	getFaqWidgetCfgDefList,
	getVoiceWidgetCfgList,
	getVoiceWidgetCfgDefList,
	removeChatWidgetCfg,
	removeFaqWidgetCfg,
	removeVoiceWidgetCfg,
	removeStunTurn,
	getAgentSipStatus,
	postAgentSipStatus,
	deleteAgentSipStatus,
	getAgentSipStatusValidate,
	stunTurnStatus,
	getClassifier,
	postClassifier,
	deleteClassifier,
	getTags,
	getAdminTagList,
	getAdminTagRemove,
	postAdminTagSave,
	postAdminTagAppend,
	postAdminTagUpload,
	getQuickReplies,
	getOneQuickReply,
	getOneQuickReplyDelete,
	postQuickReply,
	getNewOrganisations,
	agentEmailAvailability,
	getCallbackAPIList,
	getCallbackListAPIById,
	postCallbackListAPI,
	deleteCallbackListAPI,
	getJWTList,
	postJWTAPI,
	deleteJWTAPI,
	getSipTrunkList,
	getSipTrunk,
	postSaveSipTrunk,
	deleteSipTrunk,
	getIVRList,
	saveIVR,
	removeIVR,
	uploadIVRPrompt,
	uploadIVRMOH,
	deployIVR,
	getTwoFAGetNewSecret,
	verifyTwoFAToken,
	postSaveSkillsCategory,
	postSaveSkills,
	postSaveSkillAgent,
	getSkillsCategory,
	getSkillsProficiency,
	getSkills,
	deleteSkill,
	deleteSkillCategory,
	deleteSkillAgent,
	getSkillAgents,
	postSaveSkillArea,
	getSkillAreas,
	deleteSkillArea,
	getAgentAssist,
	saveAgentAssist,
	getLanguageList,
	getBlacklistWords,
	deleteBlacklistWords,
	postBlacklistWords,
	getBlacklistResponse,
	getToolManager,
	deleteBlacklistResponse,
	postBlacklistResponse,
	postToolManager,
	deleteToolManager
} from './ajax';
import {
	keyAgentAdmin,
	keyEditAgentData,
	keyAgentAdminList,
	keyAgentNew,
	keyAgentActivate,
	keyAgentDeactivate,
	keyAgentValidate,
	keyAgentAdminSalutations,
	keyAgentAdminSignatures,
	keyAgentUnlock,
	keyAddAgentMP,
	keyDeleteReviewAddress,
	keyDeleteReviewAgents,
	keyGetOneReviewAddress,
	keyGetReviewAddresses,
	keySaveReviewAddress,
	keySaveAgentForReview,
	keyGetReviewAgents,
	keyRemoveAgentMP,
	keyAddAgentAvatar,
	keyRemoveAgentAvatar,
	keyAgentUploadList,
	keyAutoTagUploadList,
	keyAgentImportStatus,
	keyGetAgentValidateExternalID,
	keyAgentWordlist,
	keyEditAgentWordlist,
	keySaveAgentWordlist,
	keyDeleteAgentWordlist,
	keyDeleteTemplate,
	keyGetSignatures,
	keyGetSalutations,
	keyGetTemplates,
	keyGetOneSignature,
	keyGetOneSalutation,
	keyGetOneTemplate,
	keySaveSignature,
	keySaveSalutation,
	keyDeleteSignature,
	keyDeleteSalutation,
	keyGetAddressbook,
	keyEditAddressbook,
	keySaveAddressbook,
	keyGetErrandInternalState,
	keyGetOneErrandInternalState,
	keySaveErrandInternalState,
	keySaveTemplate,
	keyDeleteAddressbook,
	keyUploadAddressbook,
	keyGetExternalExpertAddressList,
	keySaveExternalExpertAddressList,
	keyRemoveExternalExpertAddressList,
	keyRemoveAvatarEEAddressList,
	keyUploadReviewAddressFile,
	keyGetAccountEmail,
	keyGetOneAccountEmail,
	keyDeleteAccountEmail,
	keySaveAccountEmail,
	keyGetAccountLine,
	keyGetOneAccountLine,
	keyDeleteAccountLine,
	keySaveAccountLine,
	keyGetAccountLinkedIn,
	keyGetOneAccountLinkedIn,
	keyDeleteAccountLinkedIn,
	keySaveAccountLinkedIn,
	keyGetAccountJira,
	keyGetOneAccountJira,
	keyDeleteAccountJira,
	keySaveAccountJira,
	keyGetAccountHubSpot,
	keyGetOneAccountHubSpot,
	keyDeleteAccountHubSpot,
	keySaveAccountHubSpot,
	keyGetAccountMSTeams,
	keyGetOneAccountMSTeams,
	keyDeleteAccountMSTeams,
	keySaveAccountMSTeams,
	keyGetAccountGoogleReview,
	keyGetOneAccountGoogleReview,
	keyDeleteAccountGoogleReview,
	keySaveAccountGoogleReview,
	keyGetAccountGoogleChat,
	keyGetOneAccountGoogleChat,
	keyDeleteAccountGoogleChat,
	keySaveAccountGoogleChat,
	keyGetAccountGooglePlay,
	keyGetOneAccountGooglePlay,
	keyDeleteAccountGooglePlay,
	keySaveAccountGooglePlay,
	keyGetAccountSMPP,
	keyGetOneAccountSMPP,
	keyDeleteAccountSMPP,
	keySaveAccountSMPP,
	keyGetAccountTrustpilot,
	keyGetOneAccountTrustpilot,
	keyDeleteAccountTrustpilot,
	keySaveAccountTrustpilot,
	keyGetAccountTelegram,
	keyGetOneAccountTelegram,
	keyDeleteAccountTelegram,
	keySaveAccountTelegram,
	keyGetAccountWhatsApp,
	keyGetOneAccountWhatsApp,
	keyDeleteAccountWhatsApp,
	keySaveAccountWhatsApp,
	keyGetAccountTwilio,
	keyGetOneAccountTwilio,
	keyDeleteAccountTwilio,
	keySaveAccountTwilio,
	keyGetAccountViber,
	keyGetOneAccountViber,
	keyDeleteAccountViber,
	keySaveAccountViber,
	keyGetAccountSlack,
	keyGetOneAccountSlack,
	keyDeleteAccountSlack,
	keySaveAccountSlack,
	keyGetAccountYoutube,
	keyGetOneAccountYoutube,
	keyDeleteAccountYoutube,
	keySaveAccountYoutube,
	keyGetAccountFacebook,
	keyGetOneAccountFacebook,
	keyDeleteAccountFacebook,
	keySaveAccountFacebook,
	keyGetAccountTwitter,
	keyGetOneAccountTwitter,
	keyDeleteAccountTwitter,
	keySaveAccountTwitter,
	keyGetAccountInstagram,
	keyGetOneAccountInstagram,
	keyDeleteAccountInstagram,
	keySaveAccountInstagram,
	keyGetFileArchive,
	keyEditFileArchive,
	keySaveFileArchive,
	keyDeleteFileArchive,
	keyDeleteRouteKeywords,
	keyGetOneFileArchive,
	keyGetGenerativeAIDocument,
	keyPostGenerativeAIDocument,
	keyDeleteGenerativeAIDocument,
	keyGetRoutingGroups,
	keyGetOneRoutingGroups,
	keyDeleteRoutingGroups,
	keySaveRoutingGroups,
	keyGetCORSWhitelist,
	keyGetOneCORSWhitelist,
	keySaveCORSWhitelist,
	keyDeleteCORSWhitelist,
	keyGetChatIPAddressBlacklist,
	keyDeleteChatIPAddressBlacklist,
	keyGetCallRecordings,
	keyGetOneCallRecordings,
	keyGetAgentSipLogins,
	keyGetOneAgentSipLogin,
	keySaveAgentSipLogin,
	keyDeleteAgentSipLogin,
	keyGetSipPriorityNumbers,
	keyGetOneSipPrioNumber,
	keySaveSipPrioNumber,
	keyDeleteSipPrioNumber,
	keyDownloadCallRecording,
	keyGetContactCard,
	keyGetOneContactCard,
	keyRemoveContactCardAccount,
	keyGetContactCardNotes,
	keyDeleteContactCardNotes,
	keyDeleteContactCard,
	keySaveContactCard,
	keyMergeContactCard,
	keyCustomerAvatar,
	keyGetRouteKeywords,
	keyGetOneRouteKeywords,
	keySaveRouteKeywords,
	keyGetRouteAutoTags,
	keyGetOneRouteAutoTags,
	keyDeleteRouteAutoTags,
	keyGetRouteSip,
	keySaveRouteSip,
	keyGetOneRouteSip,
	keyDeleteRouteSip,
	keyGetStunTurnList,
	keyAddStunTurn,
	keyGetCompanyList,
	keyGetConfigChangeLog,
	keyAddNewCompany,
	keyRemoveCompany,
	keyGetChatWidgetCfgList,
	keyGetChatWidgetCfgDefList,
	keyGetFaqWidgetCfgList,
	keyGetFaqWidgetCfgDefList,
	keyGetVoiceWidgetCfgList,
	keyGetVoiceWidgetCfgDefList,
	keyAgentSipStatus,
	keyFetchAgentSipStatus,
	keyUpdateAgentSipStatus,
	keyValidateAgentSipStatus,
	keyUpdateStunTurnStatus,
	keyClassifier,
	keyFetchClassifier,
	keyUpdateClassifier,
	keyGetTags,
	keyGetTagsList,
	keyRemoveTags,
	keySaveAdminTag,
	keyAppendAdminTag,
	keyUploadTags,
	keyGetQuickReplies,
	keyGetOneQuickReply,
	keySaveQuickReply,
	keyDeleteQuickReply,
	keyGetOrganisations,
	keyAgentEmailAvailability,
	keyGetCallbackAPIList,
	keySaveCallbackAPI,
	keyRemoveCallbackAPI,
	keyGetJWTList,
	keySaveJWTAPI,
	keyRemoveJWTAPI,
	keyCallSipTrunkList,
	keyGetOneSipTrunk,
	keySaveSipTrunk,
	keyDeleteSipTrunk,
	keyCallIVRList,
	keyCallIVRSave,
	keyCallIVRDelete,
	keyCallIVRPromptUpload,
	keyCallIVRMOHUpload,
	keyCallIVRDeploy,
	keyTwoFANewSecret,
	keyVerifyTwoFAToken,
	keyGetSkillsCategory,
	keyGetSkills,
	keyGetSkillProficiency,
	keyGetSkillAgents,
	keyGetSkillAreas,
	keyAgentAssist,
	keyLanguageList,
	keyGetBlacklist,
	keySaveBlacklist,
	keyDeleteBlacklist,
	keyGetBlacklistResp,
	keySaveBlacklistResp,
	keyDeleteBlacklistResp,
	keyGetToolManager,
	keySaveToolManager,
	keyDeleteToolManager

} from '../../constants/keys';
import { 
	ReviewAddressesFile
	, AdminAccountEmail
	, AdminAccountLinkedIn
	, AdminAccountJira
	, AdminAccountHubSpot
	, AdminAccountMSTeams
	, AdminAccountMSTeamsCreateGroup
	, AdminAccountMSTeamsManageGroup
	, AdminAccountGoogleReview
	, AdminAccountGoogleChat
	, AdminAccountGooglePlay
	, AdminAccountSMPP
	, AdminAccountTrustpilot
	, AdminAccountTelegram
	, AdminAccountWhatsApp
	, AdminAccountTwilio
	, AdminAccountViber
	, AdminAccountSlack
	, AdminAccountYoutube
	, AdminAccountInstagram
	, AdminAccountLine
	, AdminAccountTwitter
	, AdminAccountFacebook
} from '../../constants/endpoints';
import {
	M_MY_SALUTATION
	, M_MY_SIGNATURE
	, M_REVIEW_KEYWORDS
	, M_REVIEW_AGENTS
	, M_SALUTATION
	, M_SIGNATURE
	, M_TEMPLATE
	, M_TEMPLATE_WHATSAPP
	, M_ERRANDINTERNALSTATE
	, M_ROUTING_KEYWORDS
	, M_ROUTING_GROUPS
	, M_ROUTING_AUTOTAGS
	, M_ROUTING_SIP
	, M_STUN_TURN
	, M_CALL_RECORDINGS
	, M_AGENT_SIP_LOGINS
	, M_SIP_PRIORITY_NUMBERS
	, M_CORS_WHITELIST
	, M_CHAT_IP_BLACKLIST
	, M_CONTACT_CARDS
	, INPUT_EMPTY_WARNING
	, MESSAGE_INVALID_EMAIL
	, MESSAGE_INVALID_PHONE_NO
	, MESSAGE_INVALID_PASSWORD_FORMAT
	, DUPLICATE_AGENT_EXTERNAL_ID
	, ADMIN_VIEW_MAP
	, TMR_AGENT_IMPORT_STATUS
	, UPLOAD_AGENT_STARTED
	, UPLOAD_AGENT_INPROGRESS
	, UPLOAD_AGENT_FINISH
	, M_CHATWIDGETDL
	, M_FAQWIDGETDL
	, M_VOICEWIDGETDL
	, INVALID_JSON_ERROR
	, M_AGENTSIPSTATUS
	, INPUT_SPACE_WARNING
	, DUPLICATE_SIP_STATUS_NAME
	, M_CLASSIFIER
	, INTERACTIVE_LIST_TYPE
	, INTERACTIVE_BUTTON_TYPE
	, M_ACCOUNTS
	, M_ACC_EMAIL
	, M_ACC_HUBSPOT
	, M_ACC_LINE
	, M_ACC_LINKEDIN
	, M_ACC_JIRA
	, M_ACC_MSTEAMS
	, M_ACC_GOOGLEREVIEW
	, M_ACC_GOOGLECHAT
	, M_ACC_GOOGLEPLAY
	, M_ACC_SMPP
	, M_ACC_TRUSTPILOT
	, M_ACC_TELEGRAM
	, M_ACC_WHATSAPP
	, M_ACC_TWILIO
	, M_ACC_VIBER
	, M_ACC_SLACK
	, M_ACC_YOUTUBE
	, M_QUICK_REPLY
	, QUICK_REPLY_MESSAGE_TEXT
	, M_ACC_FACEBOOK
	, M_ACC_INSTAGRAM
	, M_ACC_TWITTER
	, EMAIL_ADDRESS_CANNOT_USE
	, FORM_CREATEGROUP
	, FORM_MANAGEGROUP
	, M_TAG
	, M_API_CALLBACK
	, M_API_ACCESSTOKENS
	, M_CALL_IVR
	, M_CALL_SIP_TRUNK
	, M_ADDRESSBOOK
	, DARK_MODE_APPEARANCE
	, M_GENERATIVE_AI_DOCUMENT
	, M_BLACKLIST
	, M_BLACKLISTRESP
	, M_LLM_TOOL_MANAGER
} from '../../../common/v5/constants';
import {
	isSST,
	isValidEmail,
	isValidPhoneNo,
	addressValidation,
	isValidPassword,
	isAccount,
	checkInvalidCharacter,
	removeInvalidCharacter,
	pleaseWaitString
} from '../../../common/v5/helpers';
import {
	clearPopWaiting
	, popError
	, popErrorOnly
	, popPleaseWait
	, togglePopAlert
	, togglePopWaiting
} from '../hmf';
import {
	customConfirm
} from './hmf';
import {
	changeAvatarPreview,
	toggleAgentEdit,
	toggleAdminEdit,
	toggleAdminFormType,
	resetAdminInput,
	setAdminInput,
	uploadAgentInProgress,
	appendSubTags,
	removeSubTags,
	getAdminDataFromList,
	adminActionAlert,
	updateAgentSkill,
	adminActionStatus
} from '../admin';
import {
	async,
	createActionCreators,
	createBroadcaster,
	loadOnceCreator,
	periodicPoll
} from '../../util';
import { centionWebURLWithIdAndQuery } from './common';
import {
	fetchWorkflowSettings,
	onceAgentAreas,
	onceConnectedAgentAreas,
	onceKnowledgeBaseList
	//,getTwoFAGetNewSecretAsync
} from './workflow';
import {
	admin
	, adminAdmin
	, adminStateByKey
	, getActiveAdminId
	, getCurrentAdminView
	, mainTagsListSelector
	, tagsListSelector
} from '../../selectors/admin';
import {
	getMyId
	, getCollabSlackEnabled
	, getCollabJiraEnabled
	, getCollabMSTeamEnabled
	, getCollabGoogleChatEnabled
} from '../../selectors/workflow';
import { createOneErrandNotes } from './errand'


const agentData = (p) => async(getAgentData(p),
	admin[keyAgentAdmin]
);

export const agentList = (p) => async(getAgentList(p),
	admin[keyAgentAdminList]
);

const defAgentListParam = { searchText: '', filter: '' }

const loadOnce = (key, ajax) => loadOnceCreator(
	admin,
	key,
	ajax,
	adminStateByKey
)

export const onceLanguageList = loadOnce(keyLanguageList, getLanguageList)
const onceDefaultAgentListBase = loadOnce(
	keyAgentAdminList,
	() => getAgentList(defAgentListParam)
)

export const onceDefaultAgentList = createBroadcaster(onceDefaultAgentListBase)

export const agentCreateNew = (p) => async(getAgentNew(p),
	admin[keyAgentNew]
);

const activateAnAgent = (p) => async(activateAgent(p),
	admin[keyAgentActivate]
);

const deactivateAnAgent = (p) => async(deactivateAgent(p),
	admin[keyAgentDeactivate]
);

const unlockAnAgent = (p) => async(unlockAgent(p),
	admin[keyAgentUnlock]
);

const doUploadAgentList = (p) => async(uploadAgentList(p),
	admin[keyAgentUploadList]
);

const doUploadAutoTagList = (p) => async(uploadAutoTagList(p),
	admin[keyAutoTagUploadList]
);

export const validateAgent = (p) => async(validateAgentLoginName(p),
	admin[keyAgentValidate]
);

export const checkAgentEmailAvail = (id, email, prefMode) => async(agentEmailAvailability({id: id, email:email, prefMode: prefMode}),
	admin[keyAgentEmailAvailability]
);

export const doActivateAgent = (id) => (dispatch, getState) => {
	return dispatch(activateAnAgent(id))
		.then((result) => {
			const adminState = getState().app.admin.admin;
			let adminFilter = adminState.filter;
			let params = {
				searchText: adminFilter.wordFilter,
				filter: adminFilter.agentTypeFilter
			}
			dispatch(fetchAdminList(ADMIN_VIEW_MAP["agents"], params));
		});
}

export const doDeactivateAgent = (id) => (dispatch, getState) => {
	return dispatch(deactivateAnAgent(id))
		.then((result) => {
			const adminState = getState().app.admin.admin;
			let adminFilter = adminState.filter;
			let params = {
				searchText: adminFilter.wordFilter,
				filter: adminFilter.agentTypeFilter
			}
			dispatch(fetchAdminList(ADMIN_VIEW_MAP["agents"], params));
		});
}

export const doActivateAgentSipStatus = (id, status) => (dispatch, getState) => {
	return dispatch(removeAgentSipStatus({
		id: id,
		active: status
	}))
		.then((result) => {
			dispatch(fetchAdminList(M_AGENTSIPSTATUS));
		});
}

const changeStunTurnStatus = (p) => async(stunTurnStatus(p), admin[keyUpdateStunTurnStatus]);

export const doActivateStunTurnStatus = (id, status) => (dispatch, getState) => {
	return dispatch(changeStunTurnStatus({
		id: id,
		active: status
	}))
		.then((result) => {
			dispatch(fetchStunTurnList());
		});
}

export const doUnlockAgent = (id) => (dispatch, getState) => {
	return dispatch(unlockAnAgent(id))
		.then((result) => {
			const adminState = getState().app.admin.admin;
			let adminFilter = adminState.filter;
			let params = {
				searchText: adminFilter.wordFilter,
				filter: adminFilter.agentTypeFilter
			}
			dispatch(fetchAdminList(ADMIN_VIEW_MAP["agents"], params));
		});
}

const getAgentSalutation = (p) => async(getAgentSalutations(p),
	admin[keyAgentAdminSalutations]
);

const getAgentSignature = (p) => async(getAgentSignatures(p),
	admin[keyAgentAdminSignatures]
);

const addAgentMsgPersonalisation = (p) => async(addAgentMP(p),
	admin[keyAddAgentMP]
);

const removeAgentMsgPersonalisation = (p) => async(removeAgentMP(p),
	admin[keyRemoveAgentMP]
);

const saveAgentData = (p) => async(updateAgentData(p),
	admin[keyEditAgentData]
);

export const doAddAgentPrefAvatar = (p) => async(addAgentAvatar(p),
	admin[keyAddAgentAvatar]
);

export const doDeleteAgentPrefAvatar = (p) => async(removeAgentAvatar(p),
	admin[keyRemoveAgentAvatar]
);

export const fetchAgentWordlist = (p) => async(getAgentWordList(p),
	admin[keyAgentWordlist]
);

export const getWordlistData = (p) => async(loadAgentWordList(p),
	admin[keyEditAgentWordlist]
);

const saveWordlistData = (p) => async(saveAgentWordList(p),
	admin[keySaveAgentWordlist]
);

export const removeWordlist = (p) => async(removeAgentWordlist(p),
	admin[keyDeleteAgentWordlist]
);

export const fetchStunTurnList = (p) => async(getStunTurnList(p),
	admin[keyGetStunTurnList]
);

const saveNewStunTurn = (p) => async(saveStunTurn(p),
	admin[keyAddStunTurn]
);

export const fetchCompanyList = (p) => async(getCompanyList(p),
	admin[keyGetCompanyList]
);

const saveNewCompanies = (p) => async(saveNewCompany(p),
	admin[keyAddNewCompany]
);

export const fetchConfigChangeLog = (p) => async(getConfigChangeLog(p),
	admin[keyGetConfigChangeLog]
);

const fetchSignatures = (p) => async(getSignatures(p),
	admin[keyGetSignatures]
);

const fetchSalutations = (p) => async(getSalutations(p),
	admin[keyGetSalutations]
);

export const fetchOneSignature = (p) => async(getOneSignature(p),
	admin[keyGetOneSignature]
);

export const oneRouteKeywords = id => async(getOneRouteKeywords(id),
	admin[keyGetOneRouteKeywords]
);

export const oneRouteAutoTags = id => async(getOneRouteAutoTags(id),
	admin[keyGetOneRouteAutoTags]
);

export const oneRouteSip = id => async(getOneRouteSip(id),
	admin[keyGetOneRouteSip]
);

export const fetchOneSalutation = (p) => async(getOneSalutation(p),
	admin[keyGetOneSalutation]
);

export const fetchOneErrandInternalState = (p) =>
	async(getOneErrandInternalState(p),
		admin[keyGetOneErrandInternalState]
	);

export const fetchChatWidgetCfgList = (p) => async(getChatWidgetCfgList(p),
	admin[keyGetChatWidgetCfgList]
);

export const fetchChatWidgetCfgDefList = (p) => async(getChatWidgetCfgDefList(p),
	admin[keyGetChatWidgetCfgDefList]
);

export const fetchFaqWidgetCfgList = (p) => async(getFaqWidgetCfgList(p),
	admin[keyGetFaqWidgetCfgList]
);

export const fetchFaqWidgetCfgDefList = (p) => async(getFaqWidgetCfgDefList(p),
	admin[keyGetFaqWidgetCfgDefList]
);

export const fetchVoiceWidgetCfgList = (p) => async(getVoiceWidgetCfgList(p),
	admin[keyGetVoiceWidgetCfgList]
);

export const fetchVoiceWidgetCfgDefList = (p) => async(getVoiceWidgetCfgDefList(p),
	admin[keyGetVoiceWidgetCfgDefList]
);

const saveErrandInternalState = (p) =>
	async(postErrandInternalState(p),
		admin[keySaveErrandInternalState]
	);

const getErrandInternalState = (p) => async(getAdminErrandStateList(p),
	admin[keyGetErrandInternalState]
);

const saveSignature = (p) => async(postSignatures(p),
	admin[keySaveSignature]
);

const saveSalutation = (p) => async(postSalutations(p),
	admin[keySaveSalutation]
);
const saveRouteKeywords = (p) => async(postRouteKeywords(p),
	admin[keySaveRouteKeywords]
);

const saveRouteAutoTags = (p) => async(postRouteAutoTags(p),
	admin[keySaveRouteKeywords]
);

const removeSignature = (p) => async(deleteSignature(p),
	admin[keyDeleteSignature]
);

const removeSalutation = (p) => async(deleteSalutation(p),
	admin[keyDeleteSalutation]
);

const getAddressBooks = (p) => async(getAddressbookList(p),
	admin[keyGetAddressbook]
);

const updateAddressBook = (p) => async(editAddressbook(p),
	admin[keyEditAddressbook]
);

const createNewAddressBook = (p) => async(saveAddressbook(p),
	admin[keySaveAddressbook]
);

const deleteAddressBook = (p) => async(removeAddressbook(p),
	admin[keyDeleteAddressbook]
);

const doUploadAddressBook = (p) => async(uploadAddressbook(p),
	admin[keyUploadAddressbook]
);

const getEEAddressBooks = (p) => async(getExternalExpertAddressList(p),
	admin[keyGetExternalExpertAddressList]
);

const saveEEAddressBooks = (p) => async(saveExternalExpertAddressList(p),
	admin[keySaveExternalExpertAddressList]
);

const removeEEAddressBooks = (p) => async(deleteExternalExpertAddressList(p),
	admin[keyRemoveExternalExpertAddressList]
);

const getAccountTwitterList = (p) => async(getAccount(AdminAccountTwitter, p),
	admin[keyGetAccountTwitter]
);
const oneAccountTwitter = id => async(getOneAccount(AdminAccountTwitter, id),
	admin[keyGetOneAccountTwitter]
);
const saveAccountTwitter = (p) => async(postAccount(AdminAccountTwitter, p),
	admin[keySaveAccountTwitter]
);
const removeAccountTwitter = (p) => async(deleteAccount(AdminAccountTwitter, p),
	admin[keyDeleteAccountTwitter]
);

const getAccountInstagramList = (p) => async(getAccount( AdminAccountInstagram, p),
	admin[keyGetAccountInstagram]
);
const oneAccountInstagram = id => async(getOneAccount(AdminAccountInstagram, id),
	admin[keyGetOneAccountInstagram]
);
const saveAccountInstagram = (p) => async(postAccount(AdminAccountInstagram, p),
	admin[keySaveAccountInstagram]
);
const removeAccountInstagram = (p) => async(deleteAccount(AdminAccountInstagram, p),
	admin[keyDeleteAccountInstagram]
);
const getAccountFacebookList = (p) => async(getAccount(AdminAccountFacebook, p),
	admin[keyGetAccountFacebook]
);
const oneAccountFacebook = id => async(getOneAccount(AdminAccountFacebook, id),
	admin[keyGetOneAccountFacebook]
);
const saveAccountFacebook = (p, type) => async(postAccountFacebook(p, type),
	admin[keySaveAccountFacebook]
);
const removeAccountFacebook = (p) => async(deleteAccount(AdminAccountFacebook, p),
	admin[keyDeleteAccountFacebook]
);
const getAccountLineList = (p) => async(getAccount(AdminAccountLine, p),
	admin[keyGetAccountLine]
);
const oneAccountLine = id => async(getOneAccount(AdminAccountLine, id),
	admin[keyGetOneAccountLine]
);
const saveAccountLine = (p) => async(postAccount(AdminAccountLine, p),
	admin[keySaveAccountLine]
);
const removeAccountLine = (p) => async(deleteAccount(AdminAccountLine, p),
	admin[keyDeleteAccountLine]
);
const getAccountLinkedInList = (p) => async(getAccount(AdminAccountLinkedIn, p),
	admin[keyGetAccountLinkedIn]
);
const oneAccountLinkedIn = id => async(getOneAccount(AdminAccountLinkedIn, id),
	admin[keyGetOneAccountLinkedIn]
);
const saveAccountLinkedIn = (p) => async(postAccount(AdminAccountLinkedIn, p),
	admin[keySaveAccountLinkedIn]
);
const removeAccountLinkedIn = (p) => async(deleteAccount(AdminAccountLinkedIn, p),
	admin[keyDeleteAccountLinkedIn]
);
const getAccountJiraList = (p) => async(getAccount(AdminAccountJira, p),
	admin[keyGetAccountJira]
);
const oneAccountJira = id => async(getOneAccount(AdminAccountJira, id),
	admin[keyGetOneAccountJira]
);
const saveAccountJira = (p) => async(postAccount(AdminAccountJira, p),
	admin[keySaveAccountJira]
);
const removeAccountJira = (p) => async(deleteAccount(AdminAccountJira, p),
	admin[keyDeleteAccountJira]
);
const getAccountHubSpotList = (p) => async(getAccount(AdminAccountHubSpot, p),
	admin[keyGetAccountHubSpot]
);
const oneAccountHubSpot = id => async(getOneAccount(AdminAccountHubSpot, id),
	admin[keyGetOneAccountHubSpot]
);
const saveAccountHubSpot = (p) => async(postAccount(AdminAccountHubSpot, p),
	admin[keySaveAccountHubSpot]
);
const removeAccountHubSpot = (p) => async(deleteAccount(AdminAccountHubSpot, p),
	admin[keyDeleteAccountHubSpot]
);
const getAccountMSTeamsList = (p) => async(getAccount(AdminAccountMSTeams, p),
	admin[keyGetAccountMSTeams]
);
const oneAccountMSTeams = id => async(getOneAccount(AdminAccountMSTeams, id),
	admin[keyGetOneAccountMSTeams]
);
const saveAccountMSTeams = (p) => async(postAccount(AdminAccountMSTeams, p),
	admin[keySaveAccountMSTeams]
);
const createGroupAccountMSTeams = (p) => async(postAccount(AdminAccountMSTeamsCreateGroup, p),
	admin[keySaveAccountMSTeams]
);
const manageGroupAccountMSTeams = (p) => async(postAccount(AdminAccountMSTeamsManageGroup, p),
	admin[keySaveAccountMSTeams]
);
const removeAccountMSTeams = (p) => async(deleteAccount(AdminAccountMSTeams, p),
	admin[keyDeleteAccountMSTeams]
);
const getAccountGoogleReviewList = (p) => async(getAccount(AdminAccountGoogleReview, p),
	admin[keyGetAccountGoogleReview]
);
const oneAccountGoogleReview = id => async(getOneAccount(AdminAccountGoogleReview, id),
	admin[keyGetOneAccountGoogleReview]
);
const saveAccountGoogleReview = (p) => async(postAccount(AdminAccountGoogleReview, p),
	admin[keySaveAccountGoogleReview]
);
const removeAccountGoogleReview = (p) => async(deleteAccount(AdminAccountGoogleReview, p),
	admin[keyDeleteAccountGoogleReview]
);
const getAccountGoogleChatList = (p) => async(getAccount(AdminAccountGoogleChat, p),
	admin[keyGetAccountGoogleChat]
);
const oneAccountGoogleChat = id => async(getOneAccount(AdminAccountGoogleChat, id),
	admin[keyGetOneAccountGoogleChat]
);
const saveAccountGoogleChat = (p) => async(postAccount(AdminAccountGoogleChat, p),
	admin[keySaveAccountGoogleChat]
);
const removeAccountGoogleChat = (p) => async(deleteAccount(AdminAccountGoogleChat, p),
	admin[keyDeleteAccountGoogleChat]
);
const getAccountGooglePlayList = (p) => async(getAccount(AdminAccountGooglePlay, p),
	admin[keyGetAccountGooglePlay]
);
const oneAccountGooglePlay = id => async(getOneAccount(AdminAccountGooglePlay, id),
	admin[keyGetOneAccountGooglePlay]
);
const saveAccountGooglePlay = (p) => async(postAccount(AdminAccountGooglePlay, p),
	admin[keySaveAccountGooglePlay]
);
const removeAccountGooglePlay = (p) => async(deleteAccount(AdminAccountGooglePlay, p),
	admin[keyDeleteAccountGooglePlay]
);
const getAccountSMPPList = (p) => async(getAccount(AdminAccountSMPP, p),
	admin[keyGetAccountSMPP]
);
const oneAccountSMPP = id => async(getOneAccountSMPP(AdminAccountSMPP, id),
	admin[keyGetOneAccountSMPP]
);
const saveAccountSMPP = (p) => async(postAccount(AdminAccountSMPP, p),
	admin[keySaveAccountSMPP]
);
const removeAccountSMPP = (p) => async(deleteAccountSMPP(AdminAccountSMPP, p),
	admin[keyDeleteAccountSMPP]
);
const getAccountTrustpilotList = (p) => async(getAccount(AdminAccountTrustpilot, p),
	admin[keyGetAccountTrustpilot]
);
const oneAccountTrustpilot = id => async(getOneAccount(AdminAccountTrustpilot, id),
	admin[keyGetOneAccountTrustpilot]
);
const saveAccountTrustpilot = (p) => async(postAccount(AdminAccountTrustpilot, p),
	admin[keySaveAccountTrustpilot]
);
const removeAccountTrustpilot = (p) => async(deleteAccount(AdminAccountTrustpilot, p),
	admin[keyDeleteAccountTrustpilot]
);
const getAccountTelegramList = (p) => async(getAccount(AdminAccountTelegram, p),
	admin[keyGetAccountTelegram]
);
const oneAccountTelegram = id => async(getOneAccount(AdminAccountTelegram, id),
	admin[keyGetOneAccountTelegram]
);
const saveAccountTelegram = (p) => async(postAccount(AdminAccountTelegram, p),
	admin[keySaveAccountTelegram]
);
const removeAccountTelegram = (p) => async(deleteAccount(AdminAccountTelegram, p),
	admin[keyDeleteAccountTelegram]
);
const getAccountWhatsAppList = (p) => async(getAccount(AdminAccountWhatsApp, p),
	admin[keyGetAccountWhatsApp]
);
const oneAccountWhatsApp = id => async(getOneAccount(AdminAccountWhatsApp, id),
	admin[keyGetOneAccountWhatsApp]
);
const saveAccountWhatsApp = (p) => async(postAccount(AdminAccountWhatsApp, p),
	admin[keySaveAccountWhatsApp]
);
const removeAccountWhatsApp = (p) => async(deleteAccount(AdminAccountWhatsApp, p),
	admin[keyDeleteAccountWhatsApp]
);
const createGroupAccountWhatsApp = (p) => async(postAccount(AdminAccountWhatsApp, p),
	admin[keySaveAccountWhatsApp]
);
const manageGroupAccountWhatsApp = (p) => async(postAccount(AdminAccountWhatsApp, p),
	admin[keySaveAccountWhatsApp]
);
const getAccountTwilioList = (p) => async(getAccount(AdminAccountTwilio, p),
	admin[keyGetAccountTwilio]
);
const oneAccountTwilio = p => async(getOneAccountTwilio(AdminAccountTwilio, p),
	admin[keyGetOneAccountTwilio]
);
const saveAccountTwilio = (p) => async(postAccountTwilio(AdminAccountTwilio, p),
	admin[keySaveAccountTwilio]
);
const removeAccountTwilio = (p) => async(deleteAccountTwilio(AdminAccountTwilio, p),
	admin[keyDeleteAccountTwilio]
);
const getAccountViberList = (p) => async(getAccount(AdminAccountViber, p),
	admin[keyGetAccountViber]
);
const oneAccountViber = id => async(getOneAccount(AdminAccountViber, id),
	admin[keyGetOneAccountViber]
);
const saveAccountViber = (p) => async(postAccount(AdminAccountViber, p),
	admin[keySaveAccountViber]
);
const removeAccountViber = (p) => async(deleteAccount(AdminAccountViber, p),
	admin[keyDeleteAccountViber]
);
const getAccountSlackList = (p) => async(getAccount(AdminAccountSlack, p),
	admin[keyGetAccountSlack]
);
const oneAccountSlack = id => async(getOneAccount(AdminAccountSlack, id),
	admin[keyGetOneAccountSlack]
);
const saveAccountSlack = (p) => async(postAccountSlack(AdminAccountSlack, p),
	admin[keySaveAccountSlack]
);
const removeAccountSlack = (p) => async(deleteAccountSlack(AdminAccountSlack, p),
	admin[keyDeleteAccountSlack]
);
const getAccountYoutubeList = (p) => async(getAccount(AdminAccountYoutube, p),
	admin[keyGetAccountYoutube]
);
const oneAccountYoutube = id => async(getOneAccount(AdminAccountYoutube, id),
	admin[keyGetOneAccountYoutube]
);
const saveAccountYoutube = (p) => async(postAccount(AdminAccountYoutube, p),
	admin[keySaveAccountYoutube]
);
const removeAccountYoutube = (p) => async(deleteAccount(AdminAccountYoutube, p),
	admin[keyDeleteAccountYoutube]
);
const getAccountEmailList = (p) => async(getAccount(AdminAccountEmail, p),
	admin[keyGetAccountEmail]
);
const oneAccountEmail = id => async(getOneAccount(AdminAccountEmail, id),
	admin[keyGetOneAccountEmail]
);
const saveAccountEmail = (p) => async(postAccount(AdminAccountEmail, p),
	admin[keySaveAccountEmail]
);
const removeAccountEmail = (p) => async(deleteAccount(AdminAccountEmail, p),
	admin[keyDeleteAccountEmail]
);

const getFileArchiveList = (p) => async(getFileArchive(p),
	admin[keyGetFileArchive]
);

const getGenerativeAIDocumentList = (p) => async(getGenerativeAIDocument(p),
	admin[keyGetGenerativeAIDocument]
);

const postGenerativeAIDoc = (p) => async(postGenerativeAIDocument(p),
	admin[keyPostGenerativeAIDocument]
);

const getCallRecordingList = (areas, offset, searchParams, sortParams) => async(getCallRecordings({ areas, offset, searchParams, sortParams}),
	admin[keyGetCallRecordings]
);

const oneCallRecordings = id => async(getOneCallRecordings(id),
	admin[keyGetOneCallRecordings]
);

const downloadCallRecording = id => async(getOneCallRecordings(id),
	admin[keyDownloadCallRecording]
);

const getAgentSipLoginList = () => async(getAgentSipLogins(),
	admin[keyGetAgentSipLogins]
);
const getAgentSipLogin = (id) => async(getOneAgentSipLogin(id),
	admin[keyGetOneAgentSipLogin]
);
const saveAgentSipLogin = (p) => async(postSaveAgentSipLogin(p),
	admin[keySaveAgentSipLogin]
);
const removeAgentSipLogin = (p) => async(deleteAgentSipLogin(p),
	admin[keyDeleteAgentSipLogin]
);

const getSipPriorityNumbersList = () => async(getSipPriorityNumbers(),
	admin[keyGetSipPriorityNumbers]
);
const getOneSipPrioNumber = (id) => async(getSipOnePrioNumber(id),
	admin[keyGetOneSipPrioNumber]
);
const saveSipPrioNumber = (p) => async(postSaveSipPrioNumber(p),
	admin[keySaveSipPrioNumber]
);
const removeSipPrioNumber = (p) => async(deleteSipPrioNumber(p),
	admin[keyDeleteSipPrioNumber]
);

const getOneSipTrunk = (id) => async(getSipTrunk(id),
	admin[keyGetOneSipTrunk]
);
const saveSipTrunk = (p) => async(postSaveSipTrunk(p),
	admin[keySaveSipTrunk]
);
const removeSipTrunk = (p) => async(deleteSipTrunk(p),
	admin[keyDeleteSipTrunk]
);

const getContactCardList = () => async(getContactCard(),
	admin[keyGetContactCard]
);

const oneContactCard = id => async(getOneContactCard(id),
	admin[keyGetOneContactCard]
);

const removeContactCard = p => async(deleteContactCard(p),
	admin[keyDeleteContactCard]
);
const removeContactCardAccount = id => async(deleteContactCardAccount(id),
	admin[keyRemoveContactCardAccount]
);
const contactNotes = id => async(getContactCardNotes(id),
	admin[keyGetContactCardNotes]
);
const removeContactNotes = id => async(deleteContactCardNote(id),
	admin[keyDeleteContactCardNotes]
);
const saveContactCard = p => async(postContactCard(p),
	admin[keySaveContactCard]
);
const mergeContactCard = p => async(postMergeContactCard(p),
	admin[keyMergeContactCard]
);
const removeContactCardAvatar = p => async(postCustomerDelAvatar(p),
	admin[keyCustomerAvatar]
);

const getCorsWhitelistList = () => async(getCorsWhitelist(),
	admin[keyGetCORSWhitelist]
);
const oneCorsWhitelist = (id) => async(getOneCorsWhitelist(id),
	admin[keyGetOneCORSWhitelist]
);
const saveCorsWhitelist = (p) => async(postCorsWhitelist(p),
	admin[keySaveCORSWhitelist]
);
const removeCorsWhitelist = (p) => async(deleteCorsWhitelist(p),
	admin[keyDeleteCORSWhitelist]
);

const getChatIPBlacklist = () => async(getChatIPAddressBlacklist(),
	admin[keyGetChatIPAddressBlacklist]
);
const removeChatIPBlacklist = (p) => async(deleteChatIPAddressBlacklist(p),
	admin[keyDeleteChatIPAddressBlacklist]
);

const getRoutingGroupsList = (areas) => async(getRoutingGroups({ areas }),
	admin[keyGetRoutingGroups]
);
const oneRoutingGroups = id => async(getOneRoutingGroups(id),
	admin[keyGetOneRoutingGroups]
);
const saveRoutingGroups = (p) => async(postRoutingGroups(p),
	admin[keySaveRoutingGroups]
);
const removeRoutingGroups = (p) => async(deleteRoutingGroups(p),
	admin[keyDeleteRoutingGroups]
);

const getRouteKeywordsList = (areas) => async(getRouteKeywords({ areas }),
	admin[keyGetRouteKeywords]
);
const getRouteAutoTagsList = (areas) => async(getRouteAutoTags({ areas }),
	admin[keyGetRouteAutoTags]
);
const getRouteSipList = (areas) => async(getRouteSip({ areas }),
	admin[keyGetRouteSip]
);
const saveRouteSip = (p) => async(postRouteSip(p),
	admin[keySaveRouteSip]
);
const removeRouteSip = (p) => async(deleteRouteSip(p),
	admin[keyDeleteRouteSip]
);
const getBlacklist = (areas) => async(getBlacklistWords({ areas }),
	admin[keyGetBlacklist]
);

const saveBlacklist = (p) =>  async(postBlacklistWords(p),
	admin[keySaveBlacklist]
);
const removeBlacklist = (p) =>  async(deleteBlacklistWords(p),
	admin[keyDeleteBlacklist]
);

const getBlacklistResp = (areas) => async(getBlacklistResponse({ areas }),
	admin[keyGetBlacklistResp]
);
const saveBlacklistResp = (p) =>  async(postBlacklistResponse(p),
	admin[keySaveBlacklistResp]
);

const removeBlacklistResp = (p) =>  async(deleteBlacklistResponse(p),
	admin[keyDeleteBlacklistResp]
);

const getToolManagerResp = (areas) => async(getToolManager({ areas }),
	admin[keyGetToolManager]
);
const saveToolManager = (p) =>  async(postToolManager(p),
	admin[keySaveToolManager]
);

const removeToolManager = (p) =>  async(deleteToolManager(p),
	admin[keyDeleteToolManager]
);
const removeFileArchive = (p) => async(deleteFileArchive(p),
	admin[keyDeleteFileArchive]
);
const removeRouteKeywords = (p) => async(deleteRouteKeywords(p),
	admin[keyDeleteRouteKeywords]
);

const removeAutoTags = (p) => async(deleteRouteAutoTags(p),
	admin[keyDeleteRouteAutoTags]
);

export const oneFileArchive = id => async(getOneFileArchive(id),
	admin[keyGetOneFileArchive]
);

const doRemoveEEAvatar = (p) => async(removeEEAddressAvatar(p),
	admin[keyRemoveAvatarEEAddressList]
);

// review address
const reviewAddressList = areas => async(
	getReviewAddresses({ areas })
	, admin[keyGetReviewAddresses]
);

const reviewAgentList = agents => async(
	getReviewAgents({ agents })
	, admin[keyGetReviewAgents]
);

const oneReviewAddress = id => async(
	getOneReviewAddress(id)
	, admin[keyGetOneReviewAddress]
);

const createReviewAddress = p => async(
	postReviewAddress(p)
	, admin[keySaveReviewAddress]
);

const createAgentForReview = p => async(
	postReviewByAgent(p)
	, admin[keySaveAgentForReview]
);

const editReviewAddress = (id, p) => async(
	postOneReviewAddress(id, p)
	, admin[keySaveReviewAddress]
);

const getTagsList = (p) => async(getTags(p),
	admin[keyGetTags]
);

const getAdminTagsList = (p) => async(getAdminTagList(p),
	admin[keyGetTagsList]
);

const removeTagFromList = (p) => async(getAdminTagRemove(p),
	admin[keyRemoveTags]
);

const adminTagSave = (p) => async(postAdminTagSave(p),
	admin[keySaveAdminTag]
);

export const adminDoTagAppend = (p) => async(postAdminTagAppend(p),
	admin[keyAppendAdminTag]
);

export const adminTagAppend = (p, view, newParent) => (dispatch, getState) => {
	dispatch(popPleaseWait(I("adding tag")));
	const { id, tags } = JSON.parse(p.params);
	return dispatch(adminDoTagAppend(p))
		.then(() => {
			dispatch(clearPopWaiting())
				.then(() => {
					dispatch(appendSubTags(id, tags));
					if(!newParent) {
						dispatch(fetchAdminList(view));
					}else {
						dispatch(setAdminInput("id", id));
					}
					dispatch(setAdminInput("newSubTags", []));
				})
		})
		.catch(err => dispatch(popError(err)))
		.catch(() => dispatch(clearPopWaiting()))
		;
};

const uploadTagsList = (p) => async(postAdminTagUpload(p),
	admin[keyUploadTags]
);

const getNewOrganisationsList = (p) => async(getNewOrganisations(p),
	admin[keyGetOrganisations]
);

const uploadReviewAddressFile = ({ name, mime, content }) => async(
	postReviewAddressesFile({
		filename: name
		, fileType: mime
		, content
	})
	, admin[keyUploadReviewAddressFile]
);

const invalidIdAlert = id => togglePopAlert(I("Invalid id {ID}").replace("{ID}", id));

const alertEmptyInput = (dispatch, field) => {
	dispatch(togglePopAlert(INPUT_EMPTY_WARNING +" "+ (field ? field : "")));
	dispatch(adminActionStatus({ status: 3, msg: INPUT_EMPTY_WARNING }));
	return;
}

const saveReviewAddress = (createNew, id, keywords, reviewType, areas) => {
	const p = { keywords, reviewType, areas };
	if (createNew) {
		return createReviewAddress(p);
	} else if (id > 0) {
		return editReviewAddress(id, p);
	}
	return invalidIdAlert(id);
};

const saveAgentForReview = (createNew, id, agents) => {
	const p = { agents };
	if (createNew) {
		return createAgentForReview(p);
	}
	return invalidIdAlert(id);
};

const removeReviewAddress = id => async(
	deleteReviewAddress(id)
	, admin[keyDeleteReviewAddress]
);

const removeReviewAgents = id => async(
	deleteReviewAgent(id)
	, admin[keyDeleteReviewAgents]
);

export const uploadReviewAddresses = file => dispatch => {
	dispatch(popPleaseWait(I("upload file")));
	return dispatch(uploadReviewAddressFile(file))
		.then(() => dispatch(clearPopWaiting()))
		.catch(err => dispatch(popError(err)))
		.catch(() => dispatch(clearPopWaiting()))
		;
};

export const downloadReviewAddress = format => (dispatch, getState) => {
	const param = { areas: adminAdmin(getState()).filter.selectedAreaFilter }
	if (format) {
		param.format = format;
	}
	try {
		return window.open(
			centionWebURLWithIdAndQuery(ReviewAddressesFile, undefined, param)
			, '_blank'
		);
	} catch (err) {
		return dispatch(popErrorOnly(err));
	}
};

// templates
const templates = q => async(getTemplates(q), admin[keyGetTemplates]);

const oneTemplate = id => async(
	getOneTemplate({ id })
	, admin[keyGetOneTemplate]
);

const saveTemplate = p => async(postTemplates(p), admin[keySaveTemplate]);

const removeTemplate = id => async(
	getOneTemplateDelete(id)
	, admin[keyDeleteTemplate]
);
// quick replies
const quickReplies = q => async(getQuickReplies(q), admin[keyGetQuickReplies]);

const oneQuickReply = id => async(
	getOneQuickReply({id})
	, admin[keyGetOneQuickReply]
);

const saveQuickReply = p => async(postQuickReply(p), admin[keySaveQuickReply]);

const removeQuickReply = id => async(
	getOneQuickReplyDelete(id)
	, admin[keyDeleteQuickReply]
);

const fetchAgentSipStatus = (p) => async(getAgentSipStatus(p), admin[keyAgentSipStatus]);
const oneAgentSipStatus = (p) => async(getAgentSipStatus(p), admin[keyFetchAgentSipStatus]);
const saveAgentSipStatus = (p) => async(postAgentSipStatus(p), admin[keyUpdateAgentSipStatus]);
const removeAgentSipStatus = (p) => async(deleteAgentSipStatus(p), admin[keyUpdateAgentSipStatus]);
export const validateAgentSipStatus = (p) => async(getAgentSipStatusValidate(p), admin[keyValidateAgentSipStatus]);

const fetchClassifier = (p) => async(getClassifier(p), admin[keyClassifier]);
const oneClassifier = (p) => async(getClassifier(p), admin[keyFetchClassifier]);
const saveClassifier = (p) => async(postClassifier(p), admin[keyUpdateClassifier]);
const removeClassifier = (p) => async(deleteClassifier(p), admin[keyUpdateClassifier]);

//callback API stuff
const fetchCallbackAPIList = (p) => async(getCallbackAPIList(p), admin[keyGetCallbackAPIList]);
const saveCallbackAPI = (p) => async(postCallbackListAPI(p), admin[keySaveCallbackAPI]);
const removeCallbackAPI = (p) => async(deleteCallbackListAPI(p), admin[keyRemoveCallbackAPI]);

//JWT API stuff
const fetchJWTAPIList = (p) => async(getJWTList(p), admin[keyGetJWTList]);
const saveJWTAPI = (p) => async(postJWTAPI(p), admin[keySaveJWTAPI]);
const removeJWTAPI = (p) => async(deleteJWTAPI(p), admin[keyRemoveJWTAPI]);

export const fetchAgentData = (p) => (dispatch, getState) => {
	return dispatch(agentData(p));
}

export const fetchAgentSalutations = (area) => (dispatch, getState) => {
	let uid = getState().app.workflow.fetchWfSettings.data.activeUserId;
	const adminState = getState().app.admin;
	const isPref = adminState.agent.prefMode;
	if (!uid) {
		uid = adminState.admin.activeId;
	}
	let param = {
		areaId: area,
		uid: uid
	}
	return dispatch(getAgentSalutation(param));
}

export const fetchAgentSignatures = (area) => (dispatch, getState) => {
	let uid = getState().app.workflow.fetchWfSettings.data.activeUserId;
	const adminState = getState().app.admin;
	const isPref = adminState.agent.prefMode;
	if (!uid) {
		uid = adminState.admin.activeId;
	}
	let param = {
		areaId: area,
		uid: uid
	}
	return dispatch(getAgentSignature(param));
}

export const addNewAgentMP = (id, type, areaId) => (dispatch, getState) => {
	let uid = getState().app.workflow.fetchWfSettings.data.activeUserId;
	const adminState = getState().app.admin;
	const isPref = adminState.agent.prefMode;
	if (!uid) {
		uid = adminState.admin.activeId;
	}
	let param = {
		mpId: id,
		type,
		uid,
		areaId
	}
	return dispatch(addAgentMsgPersonalisation(param))
		.then((result) => {
		});
}

export const deleteAgentMP = (id, type) => (dispatch, getState) => {
	const uid = getState().app.workflow.fetchWfSettings.data.activeUserId;
	let param = {
		mpId: id,
		type,
		uid
	}
	return dispatch(removeAgentMsgPersonalisation(param));
}

function dataURItoBlob(dataURI) {
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else
		byteString = unescape(dataURI.split(',')[1]);

	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], { type: mimeString });
}

function isJsonStringObj(text) {
	try {
		if (typeof JSON.parse(text) === "object") {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
}

export const addAgentPrefAvatar = (preview, isPref) => (dispatch, getState) => {
	dispatch(changeAvatarPreview(preview, isPref));
	let fd = new FormData(), i = 0;
	let rand = Math.floor(Math.random() * 6) + '' + i + '' + Math.floor('' + new Date() / 1000);
	let blob = dataURItoBlob(preview);
	fd.append("uploadedAvatar", blob);
	fd.append("localName", rand);
	dispatch(doAddAgentPrefAvatar(fd));
}

export const deleteAgentPrefAvatar = () => (dispatch, getState) => {
	const adminState = getState().app.admin;
	const isPref = adminState.agent.prefMode;
	let uid = adminState.admin.activeId;
	if (isPref) {
		uid = getState().app.workflow.fetchWfSettings.data.activeUserId;
	}
	let fd = new FormData();
	fd.append("id", uid);
	dispatch(doDeleteAgentPrefAvatar(fd))
		.then(() => {
			let typeFilter = adminState.admin.filter.agentTypeFilter
				, searchTxt = adminState.admin.filter.wordFilter;
			let params = { searchText: searchTxt, filter: typeFilter }
			dispatch(fetchAdminList(ADMIN_VIEW_MAP["agents"], params));
		});
}

function intToBool(val) {
	if (val === 1 || val === true) {
		return true;
	} else {
		return false;
	}
}

const doGetAgentValidateExternalID = (p) => async(getAgentValidateExternalID(p),
	admin[keyGetAgentValidateExternalID]
);

export const doGetTwoFAGetNewSecret = () => async(getTwoFAGetNewSecret(),
	admin[keyTwoFANewSecret]
);

export const doVerifyTwoFAToken = (p) => async(verifyTwoFAToken(p),
	admin[keyVerifyTwoFAToken]
);

export const AgentValidateExternalID = (isPref) => (dispatch, getState) => {
	const state = getState();
	const adminState = state.app.admin;
	let externalID = adminState.agent.input.externalID;
	let agentId = state.app.workflow.fetchWfSettings.data.activeUserId;
	if (!isPref) {
		agentId = adminState.admin.activeId;
		if (agentId < 1) {
			agentId = 0;
		}
		externalID = adminState.admin.input.externalID;
	}
	dispatch(doGetAgentValidateExternalID({ agentId: agentId, externalID: externalID }))
		.then((rs) => {
			if (!(rs.success)) {
				window.alert(DUPLICATE_AGENT_EXTERNAL_ID);
				return;
			} else {
				dispatch(setAgentData(isPref));
			}
		});
}

export const agentUploadListAttachment = (files) => (dispatch, getState) => {
	dispatch(uploadAgentInProgress(UPLOAD_AGENT_STARTED));
	dispatch(doUploadAgentList(files));
}

export const uploadAutoTagFile = (files) => (dispatch, getState) => {
	//dispatch(uploadAgentInProgress(UPLOAD_AGENT_STARTED));
	dispatch(doUploadAutoTagList(files));
}

export const uploadTagsFile = (files) => (dispatch) => {
	dispatch(popPleaseWait(I("uploading tags")));
	dispatch(uploadTagsList(files))
	.then((rs) => {
		dispatch(clearPopWaiting())
		.then(() => {
			if(rs.error || rs.err) {
				const alertMsg = {
					show: true,
					msg: rs.error,
					type: "error"
				}
				dispatch(adminActionAlert(alertMsg));
				return;
			} else {
				if(rs.status) {
					const alertMsg = {
						show: true,
						msg: rs.status,
						type: "success"
					}
					dispatch(adminActionAlert(alertMsg));
				}
				dispatch(fetchAdminList(M_TAG));
			}
		})
		.catch(err => dispatch(popError(err)))
		.catch(() => dispatch(clearPopWaiting()))
		;
	});
}

const copyAgentData = (isPref, adminState) => {
	if (isPref) {
		return { ...adminState.agent.input }
	}
	return { ...adminState.admin.input }
}

const setAgentData = (isPref) => (dispatch, getState) => {
	const state = getState();
	const passwordMinReq = state.server.features["password-min-requirement"];
	const adminState = state.app.admin;
	const agentData = copyAgentData(isPref, adminState);
	const agentNewUILang = adminState.agent.input.interfaceLanguage;
	const previousDarkModePreference = adminState.agentData.data ? adminState.agentData.data.darkModeAppearance : DARK_MODE_APPEARANCE[0].id;
	const currentDarkModePreference = adminState.agent.input.darkModeAppearance;
	let uid = state.app.workflow.fetchWfSettings.data.activeUserId;
	let popup = adminState.show;
	let uploadStatus = adminState.admin.uploadingAgent;
	let agentCurrUILang = adminState.agentData.data ? adminState.agentData.data.interfaceLanguage : "";
	let uploadId = "";
	if (!isPref) {
		//edit mode or creating new
		uid = adminState.admin.activeId;
		agentCurrUILang = adminState.admin.input.interfaceLanguage;
		if (uploadStatus === UPLOAD_AGENT_STARTED) {
			uid = 0;
			if (adminState.agentUploadList.data) {
				uploadId = adminState.agentUploadList.data.id;
			}
			agentData.loginName = "temp_Agent_" + uploadId;
			agentData.userName = "temp_Agent_" + uploadId;
			agentData.profile = false;
			agentData.uploadedFile = uploadId;
		} else {
			//create new
			if (uid < 1) {
				uid = 0;
			}
		}
		let feat = state.server.features;
		if(feat["agent.unique.email.address"]){
			let aea = adminState.admin.agentEmailAvailability;
			if( aea != null && !aea){
				dispatch(togglePopAlert(EMAIL_ADDRESS_CANNOT_USE));
				dispatch(adminActionStatus({ status: 3, msg: EMAIL_ADDRESS_CANNOT_USE }));
				return;
			}
		}
	}
	if (cflag.IsActive("2024-03-08.CEN-1919.implement.login.with.otp") && features["agent.otp"] && agentData.adminLevel === 5) {
		if (!isValidPhoneNo(agentData.phoneNumber)) {
			dispatch(togglePopAlert(MESSAGE_INVALID_PHONE_NO));
			return;
		}
		if (agentData.phoneNumber === "" && uploadId =="") {
			return alertEmptyInput(dispatch);
		}
	}
	if ((agentData.loginName === "" || agentData.userName === "") && uploadId === "") {
		return alertEmptyInput(dispatch);
	} else if ((agentData.email === "" || !isValidEmail(agentData.email)) && uploadId === "") {
		dispatch(togglePopAlert(MESSAGE_INVALID_EMAIL));
		dispatch(adminActionStatus({ status: 3, msg: MESSAGE_INVALID_EMAIL }));
		return;
	} else if (agentData.password && !isValidPassword(agentData.password, passwordMinReq)) {
		dispatch(togglePopAlert(MESSAGE_INVALID_PASSWORD_FORMAT));
		dispatch(adminActionStatus({ status: 3, msg: MESSAGE_INVALID_PASSWORD_FORMAT }));
		return;
	} else {
		agentData.isPreference = isPref;
		agentData.id = uid;
		agentData.loginName = agentData.loginName.trim();
		agentData.hotkeys = intToBool(agentData.hotkeys);
		agentData.hotkeys_markers = intToBool(agentData.hotkeys_markers);
		agentData.salutationPreview = intToBool(agentData.salutationPreview);
		agentData.signaturePreview = intToBool(agentData.signaturePreview);
		agentData.showOnlyWarnings = intToBool(agentData.showOnlyWarnings);
		agentData.playSound = intToBool(agentData.playSound);
		agentData.autoInsertPublishedAnswer = intToBool(agentData.autoInsertPublishedAnswer);
		agentData.sortHistoryAscending = intToBool(agentData.sortHistoryAscending);
		agentData.isPreference = isPref;
		agentData.blueBubbleNotification = intToBool(agentData.blueBubbleNotification);
		agentData.browserOsNotification = intToBool(agentData.browserOsNotification);
		agentData.darkMode = intToBool(agentData.darkMode);
		agentData.twofa_flag = intToBool(agentData.twofa_flag);
		dispatch(saveAgentData(agentData))
			.then((rs) => {
				if (rs.success) {
					if (uid !== 0 && ((agentCurrUILang !== agentNewUILang) || (previousDarkModePreference !== currentDarkModePreference))) {
						if (isPref) {
							location.reload();
						}
					} else {
						if (isPref) {
							dispatch(toggleAgentEdit(false, false));
							dispatch(fetchWorkflowSettings());
						} else {
							if (!popup) {
								dispatch(toggleAdminEdit(0, false));
							} else {
								dispatch(toggleAgentEdit(false, false));
							}
							dispatch(startRunAgentImportStatus());
						}
					}
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log(err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			})
	}
}

// Fetching list
export const fetchAdminList = (view, paramVal, onLoad) => (dispatch, getState) => {
	const adminState = getState().app.admin, wfState = getState().app.workflow;
	let areaList = adminState.admin.filter.selectedAreaFilter;
	let dateFrom = adminState.admin.filter.dateFromFilter;
	let dateTo = adminState.admin.filter.dateToFilter;
	let lastFieldChange = adminState.admin.filter.lastFieldChange;
	if (typeof paramVal === "undefined" || onLoad ) {
		dispatch(onceKnowledgeBaseList());
		dispatch(onceConnectedAgentAreas());
		dispatch(onceAgentAreas())
			.then((rs) => {
				if (typeof onLoad != "undefined" && onLoad) {
					let agentAreas = [];
					if (typeof rs !== "undefined") {
						agentAreas = rs.areas;
					} else {
						agentAreas = wfState.agentAreas.data ? wfState.agentAreas.data.areas : []; // TODO: wrong here as should use onlyActiveAreasSelector
					}
					if (agentAreas && agentAreas.length > 0) {
						let selectedAreas = [];
						$.each(agentAreas, function (i, v) {
							$.each(v.Areas, function (j, w) {
								const id = w.Id;
								selectedAreas.push(w.Id);
							});
						});
						if (selectedAreas.length > 0) {
							areaList = selectedAreas.join(",");
						}
					}
				}
				dispatch(getListFromView(view, paramVal, areaList, dateFrom, dateTo, lastFieldChange));
			})
			.catch(err => {
				console.log("error : ", err);
			});
	} else {
		dispatch(getListFromView(view, adminState.admin.filter.wordFilter, areaList, dateFrom, dateTo, lastFieldChange));
	}
}

const personalParam = personal => ({ params: JSON.stringify({ personal }) });

export const idPersonalParam = (id, personal) => ({ params: JSON.stringify({ id, personal }) });

const reviewAddressMethods = {
	save: (
		createNew
		, id
		, { keywords, reviewType, selectedArea }
	) => saveReviewAddress(
		createNew
		, id
		, keywords
		, reviewType
		, selectedArea
	)
	, remove: removeReviewAddress
	, list: areas => reviewAddressList(areas)
	, one: id => oneReviewAddress(id)
}
	, reviewByAgentMethods = {
		save: (
			createNew
			, id
			, { selectedAgents }
		) => saveAgentForReview(
			createNew
			, id
			, csvStringToIntArray(selectedAgents)
		)
		, remove: removeReviewAgents
		, list: agents => reviewAgentList(agents)
	}
	, accountEmailMethods = {
		list: (areas) => getAccountEmailList()
		, one: p => oneAccountEmail(p)
		, save: saveAccountEmail
		, remove: removeAccountEmail
	}
	, accountLineMethods = {
		list: (areas) => getAccountLineList()
		, one: p => oneAccountLine(p)
		, save: saveAccountLine
		, remove: removeAccountLine
	}
	, accountFacebookMethods = {
		list: (areas) => getAccountFacebookList()
		, one: p => oneAccountFacebook(p)
		, save: saveAccountFacebook
		, remove: removeAccountFacebook
	}
	, accountLinkedInMethods = {
		list: (areas) => getAccountLinkedInList()
		, one: p => oneAccountLinkedIn(p)
		, save: saveAccountLinkedIn
		, remove: removeAccountLinkedIn
	}
	, accountJiraMethods = {
		list: (areas) => getAccountJiraList()
		, one: p => oneAccountJira(p)
		, save: saveAccountJira
		, remove: removeAccountJira
	}
	, accountHubSpotMethods = {
		list: (areas) => getAccountHubSpotList()
		, one: p => oneAccountHubSpot(p)
		, save: saveAccountHubSpot
		, remove: removeAccountHubSpot
	}
	, accountMSTeamsMethods = {
		list: (areas) => getAccountMSTeamsList()
		, one: p => oneAccountMSTeams(p)
		, save: saveAccountMSTeams
		, remove: removeAccountMSTeams
		, createGroup: createGroupAccountMSTeams
		, manageGroup: manageGroupAccountMSTeams
	}
	, accountGoogleReviewMethods = {
		list: (areas) => getAccountGoogleReviewList()
		, one: p => oneAccountGoogleReview(p)
		, save: saveAccountGoogleReview
		, remove: removeAccountGoogleReview
	}
	, accountGoogleChatMethods = {
		list: (areas) => getAccountGoogleChatList()
		, one: p => oneAccountGoogleChat(p)
		, save: saveAccountGoogleChat
		, remove: removeAccountGoogleChat
	}
	, accountGooglePlayMethods = {
		list: (areas) => getAccountGooglePlayList()
		, one: p => oneAccountGooglePlay(p)
		, save: saveAccountGooglePlay
		, remove: removeAccountGooglePlay
	}
	, accountSMPPMethods = {
		list: (areas) => getAccountSMPPList()
		, one: p => oneAccountSMPP(p)
		, save: saveAccountSMPP
		, remove: removeAccountSMPP
	}
	, accountTrustpilotMethods = {
		list: (areas) => getAccountTrustpilotList()
		, one: p => oneAccountTrustpilot(p)
		, save: saveAccountTrustpilot
		, remove: removeAccountTrustpilot
	}
	, accountTelegramMethods = {
		list: (areas) => getAccountTelegramList()
		, one: p => oneAccountTelegram(p)
		, save: saveAccountTelegram
		, remove: removeAccountTelegram
	}
	, accountWhatsAppMethods = {
		list: (areas) => getAccountWhatsAppList()
		, one: p => oneAccountWhatsApp(p)
		, save: saveAccountWhatsApp
		, remove: removeAccountWhatsApp
		, createGroup: createGroupAccountWhatsApp
		, manageGroup: manageGroupAccountWhatsApp
	}
	, accountTwilioMethods = {
		list: (areas) => getAccountTwilioList()
		, one: p => oneAccountTwilio(p)
		, save: saveAccountTwilio
		, remove: removeAccountTwilio
	}
	, accountViberMethods = {
		list: (areas) => getAccountViberList()
		, one: p => oneAccountViber(p)
		, save: saveAccountViber
		, remove: removeAccountViber
	}
	, accountSlackMethods = {
		list: (areas) => getAccountSlackList()
		, one: p => oneAccountSlack(p)
		, save: saveAccountSlack
		, remove: removeAccountSlack
	}
	, accountYoutubeMethods = {
		list: (areas) => getAccountYoutubeList()
		, one: p => oneAccountYoutube(p)
		, save: saveAccountYoutube
		, remove: removeAccountYoutube
	}
	, accountTwitterMethods = {
		list: (areas) => getAccountTwitterList()
		, one: p => oneAccountTwitter(p)
		, save: saveAccountTwitter
		, remove: removeAccountTwitter
	}
	, accountInstagramMethods = {
		list: (areas) => getAccountInstagramList()
		, one: p => oneAccountInstagram(p)
		, save: saveAccountInstagram
		, remove: removeAccountInstagram
	}
	, routeKeywordsMethods = {
		save: saveRouteKeywords
		, list: (areas) => getRouteKeywordsList(areas)
		, remove: removeRouteKeywords
		, one: id => oneRouteKeywords(id)
	}
	, routeAutoTagsMethod = {
		list: (areas) => getRouteAutoTagsList(areas)
		, remove: removeAutoTags
		, one: id => oneRouteAutoTags(id)
		, save: saveRouteAutoTags
	}
	, routingGroupsMethod = {
		list: (areas) => getRoutingGroupsList(areas)
		, remove: removeRoutingGroups
		, one: id => oneRoutingGroups(id)
		, save: saveRoutingGroups
	}
	, callRecordingsMethod = {
		list: (areas, offset, searchParams, sortParams)=> getCallRecordingList(areas, offset, searchParams, sortParams)
		, one: id => oneCallRecordings(id)
		, download: id => downloadCallRecording(id)
		// , remove: removeCallRecording
	}
	, agentSipLoginMethod = {
		list: () => getAgentSipLoginList()
		, one: id => getAgentSipLogin(id)
		, save: id => saveAgentSipLogin(id)
		, remove: removeAgentSipLogin
	}
	, sipTrunkMethod = {
		list: () => fetchSipTrunkList()
		, one: id => getOneSipTrunk(id)
		, save: id => saveSipTrunk(id)
		, remove: removeSipTrunk
	}
	, sipPriorityNumbersMethod = {
		list: () => fetchSipPrioNumberList()
		, one: id => getOneSipPrioNumber(id)
		, save: id => saveSipPrioNumber(id)
		, remove: removeSipPrioNumber
	}
	, corsWhitelistMethod = {
		list: () => getCorsWhitelistList()
		, one: id => oneCorsWhitelist(id)
		, remove: removeCorsWhitelist
		, save: id => saveCorsWhitelist(id)
	}
	, chatIPBlacklistMethod = {
		list: () => getChatIPBlacklist()
		, remove: removeChatIPBlacklist
	}
	, contactCardsMethod = {
		list: () => getContactCardList()
		, one: id => oneContactCard(id)
		, notes: id => contactNotes(id)
		, addNote: p => createOneErrandNotes(p)
		, deleteNote: p => removeContactNotes(p)
		, save: p => saveContactCard(p)
		, merge: p => mergeContactCard(p)
		// , download: id => downloadCallRecording(id)
		, remove: removeContactCard
		, removeAccount: removeContactCardAccount
		, removeAvatar: id => removeContactCardAvatar(id)
	}
	, routeSipMethod = {
		list: (areas) => getRouteSipList(areas)
		, save: saveRouteSip
		, one: id => oneRouteSip(id)
		, remove: removeRouteSip
	}
	, blacklistMethod = {
		save: saveBlacklist
		, list: (areas) => getBlacklist(areas)
		, remove: removeBlacklist
	}
	, blacklistRespMethod = {
		save: saveBlacklistResp
		, list: (areas) => getBlacklistResp(areas)
		, remove: removeBlacklistResp
	}
	, toolManagerMethod = {
		save: saveToolManager
		, list: (areas) => getToolManagerResp(areas)
		, remove: removeToolManager
	}
	, salutationMethods = {
		save: saveSalutation
		, remove: removeSalutation
		, list: () => fetchSalutations(personalParam(""))
		, one: id => fetchOneSalutation(idPersonalParam(id, ""))
	}
	, signatureMethods = {
		save: saveSignature
		, remove: removeSignature
		, list: () => fetchSignatures(personalParam(""))
		, one: id => fetchOneSignature(idPersonalParam(id, ""))
	}
	, personal = "personal"
	, personalUpdater = (listFetch, oneFetch) => ({
		list: { $set: () => listFetch(personalParam(personal)) }
		, one: { $set: id => oneFetch(idPersonalParam(id, personal)) }
	})
	, errandInternalStateMethods = {
		one: id => fetchOneErrandInternalState(id)
		, save: (
			createNew
			, id
			, { selectedErrandInternalState }
		) => saveErrandInternalState(selectedErrandInternalState)
		, list: () => getErrandInternalState()
	}
	;
export const sstAsyncs = {
	[M_MY_SALUTATION]: update(
		salutationMethods
		, personalUpdater(fetchSalutations, fetchOneSalutation)
	)
	, [M_MY_SIGNATURE]: update(
		signatureMethods
		, personalUpdater(fetchSignatures, fetchOneSignature)
	)
	, [M_REVIEW_KEYWORDS]: reviewAddressMethods
	, [M_REVIEW_AGENTS]: reviewByAgentMethods
	, [M_SALUTATION]: salutationMethods
	, [M_SIGNATURE]: signatureMethods
	, [M_ERRANDINTERNALSTATE]: errandInternalStateMethods
	, [M_TEMPLATE]: {
		save: saveTemplate
		, remove: removeTemplate
		, list: templates
		, one: oneTemplate
	}
	, [M_TEMPLATE_WHATSAPP]: {
		save: saveTemplate
		, remove: removeTemplate
		, list: templates
		, one: oneTemplate
	}
	, [M_ROUTING_KEYWORDS]: routeKeywordsMethods
	, [M_BLACKLIST]: blacklistMethod
	, [M_LLM_TOOL_MANAGER]: toolManagerMethod
	, [M_BLACKLISTRESP]: blacklistRespMethod
	, [M_ROUTING_AUTOTAGS]: routeAutoTagsMethod
	, [M_ROUTING_GROUPS]: routingGroupsMethod
	, [M_CALL_RECORDINGS]: callRecordingsMethod
	, [M_AGENT_SIP_LOGINS]: agentSipLoginMethod
	, [M_SIP_PRIORITY_NUMBERS]: sipPriorityNumbersMethod
	, [M_CALL_SIP_TRUNK]: sipTrunkMethod
	, [M_CORS_WHITELIST]: corsWhitelistMethod
	, [M_CHAT_IP_BLACKLIST]: chatIPBlacklistMethod
	, [M_CONTACT_CARDS]: contactCardsMethod
	, [M_ROUTING_SIP]: routeSipMethod
	, [M_AGENTSIPSTATUS]: {
		save: saveAgentSipStatus
		, remove: removeAgentSipStatus
		, list: fetchAgentSipStatus
		, one: id => oneAgentSipStatus({id: id})
	}
	, [M_CLASSIFIER]: {
		save: saveClassifier
		, remove: removeClassifier
		, list: fetchClassifier
		, one: id => oneClassifier({id: id})
	}
	, [M_QUICK_REPLY]: {
		save: saveQuickReply
		, remove: removeQuickReply
		, list: quickReplies
		, one: oneQuickReply
	}
	}
	;
export const accountsAsyncs = {
	[M_ACCOUNTS]: accountEmailMethods
	, [M_ACC_EMAIL]: accountEmailMethods
	, [M_ACC_LINE]: accountLineMethods
	, [M_ACC_FACEBOOK]: accountFacebookMethods
	, [M_ACC_INSTAGRAM]: accountInstagramMethods
	, [M_ACC_LINKEDIN]: accountLinkedInMethods
	, [M_ACC_JIRA]: accountJiraMethods
	, [M_ACC_HUBSPOT]: accountHubSpotMethods
	, [M_ACC_MSTEAMS]: accountMSTeamsMethods
	, [M_ACC_GOOGLEREVIEW]: accountGoogleReviewMethods
	, [M_ACC_GOOGLECHAT]: accountGoogleChatMethods
	, [M_ACC_GOOGLEPLAY]: accountGooglePlayMethods
	, [M_ACC_SMPP]: accountSMPPMethods
	, [M_ACC_TRUSTPILOT]: accountTrustpilotMethods
	, [M_ACC_TELEGRAM]: accountTelegramMethods
	, [M_ACC_WHATSAPP]: accountWhatsAppMethods
	, [M_ACC_TWILIO]: accountTwilioMethods
	, [M_ACC_VIBER]: accountViberMethods
	, [M_ACC_SLACK]: accountSlackMethods
	, [M_ACC_YOUTUBE]: accountYoutubeMethods
	, [M_ACC_TWITTER]: accountTwitterMethods
}
const getListFromView = (view, paramVal, areaList, dateFrom, dateTo, lastFieldChange) => (dispatch, getState) => {
	let dispatchee, afterDispatchee, param = {}, searchTxt = "";
	const adminState = getState().app.admin;
	let agentFilter = adminState.admin.filter.selectedAgentFilter;

	//FIXME: Exception for other page, should be only for admin pages
	switch (view) {
		case ADMIN_VIEW_MAP["wordlist"]:
			if (paramVal === 0) paramVal = -1;
			if (paramVal) {
				param = { langId: paramVal };
				dispatchee = fetchAgentWordlist(param)
			} else {
				dispatchee = fetchAgentWordlist();
				afterDispatchee = getWordlistData({ id: 0 });
			}
			break;
		case M_REVIEW_KEYWORDS:
			dispatchee = sstAsyncs[M_REVIEW_KEYWORDS].list(areaList);
			break;
		case M_REVIEW_AGENTS:
			dispatchee = sstAsyncs[M_REVIEW_AGENTS].list(agentFilter);
			break;
		case M_AGENTSIPSTATUS:
			dispatchee = sstAsyncs[M_AGENTSIPSTATUS].list({
				filter: adminState.admin.filter.agentSipStatusFilter
			});
			break;
		case M_CLASSIFIER:
			dispatchee = sstAsyncs[M_CLASSIFIER].list();
			break;
		case M_MY_SIGNATURE:
			dispatchee = sstAsyncs[M_MY_SIGNATURE].list();
			break;
		case M_SIGNATURE:
			dispatchee = sstAsyncs[M_SIGNATURE].list();
			break;
		case M_MY_SALUTATION:
			dispatchee = sstAsyncs[M_MY_SALUTATION].list();
			break;
		case M_SALUTATION:
			dispatchee = sstAsyncs[M_SALUTATION].list();
			break;
		case M_TEMPLATE:
			dispatchee = sstAsyncs[M_TEMPLATE].list("errand");
			break;
		case M_TEMPLATE_WHATSAPP:
			dispatchee = sstAsyncs[M_TEMPLATE_WHATSAPP].list("wa");
			break;
		case M_QUICK_REPLY:
			dispatchee = sstAsyncs[M_QUICK_REPLY].list();
			break;
		case ADMIN_VIEW_MAP["addressbook-personal"]:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { personal: 1, searchText: searchTxt };
			dispatchee = getAddressBooks(param);
			break;
		case ADMIN_VIEW_MAP["addressbook"]:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { personal: 0, searchText: searchTxt };
			dispatchee = getAddressBooks(param);
			break;
		case ADMIN_VIEW_MAP["externalexpert-personal"]:
			param = { personal: 1, areas: areaList }
			dispatchee = getEEAddressBooks(param);
			break;
		case ADMIN_VIEW_MAP["filearchive"]:
			dispatchee = getFileArchiveList();
			break;
		case M_ROUTING_KEYWORDS:
			dispatchee = sstAsyncs[M_ROUTING_KEYWORDS].list(areaList);
			break;
		case M_BLACKLIST:
			dispatchee = getBlacklist();
			break;
		case M_BLACKLISTRESP:
			dispatchee = getBlacklistResp();
			break;
		case M_LLM_TOOL_MANAGER:
			dispatchee = getToolManagerResp();
			break;
		case M_ROUTING_GROUPS:
			dispatchee = sstAsyncs[M_ROUTING_GROUPS].list(areaList);
			break;
		case M_ROUTING_AUTOTAGS:
			dispatchee = sstAsyncs[M_ROUTING_AUTOTAGS].list(areaList);
			break;
		case M_ROUTING_SIP:
			dispatchee = sstAsyncs[M_ROUTING_SIP].list(areaList);
			break;
		case M_CALL_RECORDINGS:
			dispatchee = sstAsyncs[M_CALL_RECORDINGS].list(areaList, 1, "", "");
			break;
		case M_AGENT_SIP_LOGINS:
			dispatchee = sstAsyncs[M_AGENT_SIP_LOGINS].list();
			break;
		case M_SIP_PRIORITY_NUMBERS:
			dispatchee = sstAsyncs[M_SIP_PRIORITY_NUMBERS].list();
			break;
		case M_CORS_WHITELIST:
			dispatchee = sstAsyncs[M_CORS_WHITELIST].list();
			break;
		case M_CHAT_IP_BLACKLIST:
			dispatchee = sstAsyncs[M_CHAT_IP_BLACKLIST].list();
			break;
		case M_CONTACT_CARDS:
			dispatchee = sstAsyncs[M_CONTACT_CARDS].list(areaList);
			afterDispatchee = fetchCompanyList(param);
			break;
		case ADMIN_VIEW_MAP["externalexpert"]:
			param = { personal: false, areas: areaList }
			dispatchee = getEEAddressBooks(param);
			break;
		case ADMIN_VIEW_MAP["knowledge-bases"]:
			break;
		case ADMIN_VIEW_MAP["companies"]:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { searchText: searchTxt };
			dispatchee = fetchCompanyList(param);
			break;
		case ADMIN_VIEW_MAP["configchangelog"]:
			if (lastFieldChange != "wordFilter"){
				param = { dateFrom : dateFrom, dateTo :dateTo};
				dispatchee = fetchConfigChangeLog(param);
			}
			break;
		case ADMIN_VIEW_MAP["stunturn"]:
			dispatchee = fetchStunTurnList(param);
			break;
		case ADMIN_VIEW_MAP["agents"]:
			dispatch(startRunAgentImportStatus());
			break;
		case M_TAG:
			param = { areas: areaList }
			dispatchee = getAdminTagsList(param);
			break;
		case ADMIN_VIEW_MAP["organisations"]:
			dispatchee = getNewOrganisationsList();
			break;
		case M_ERRANDINTERNALSTATE:
			dispatchee = getErrandInternalState();
			break;
		case M_CHATWIDGETDL:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { searchText: searchTxt };
			dispatchee = fetchChatWidgetCfgList(param);
			afterDispatchee = fetchChatWidgetCfgDefList({ id: 0 });
			break;
		case M_FAQWIDGETDL:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { searchText: searchTxt };
			dispatchee = fetchFaqWidgetCfgList(param);
			afterDispatchee = fetchFaqWidgetCfgDefList({ id: 0 });
			break;
		case M_VOICEWIDGETDL:
			searchTxt = typeof paramVal === "string" ? paramVal : "";
			param = { searchText: searchTxt };
			dispatchee = fetchVoiceWidgetCfgList(param);
			afterDispatchee = fetchVoiceWidgetCfgDefList({ id: 0 });
			break;
		case M_API_CALLBACK:
			dispatchee = fetchCallbackAPIList();
			break;
		case M_API_ACCESSTOKENS:
			dispatchee = fetchJWTAPIList();
			break;
		case M_CALL_IVR:
			dispatchee = fetchIVRAdminList();
			break;
		case M_CALL_SIP_TRUNK:
			dispatchee = fetchSipTrunkList();
			break;
		case M_GENERATIVE_AI_DOCUMENT:
			dispatchee = getGenerativeAIDocumentList();
			break;
		default:
			if (process.env.NODE_ENV !== 'production') {
				console.log("no dispatcher yet for ", view);
			}
	}

	if(isAccount(view)) {
		dispatchee = accountsAsyncs[view].list(areaList);
	}

	if (view !== ADMIN_VIEW_MAP["agents"]) {
		if (dispatchee) {
			if(view === M_TAG) {
				dispatch(popPleaseWait(I("fetching tags")));
			} else if (view === M_API_CALLBACK || view === M_API_ACCESSTOKENS || view === M_CALL_IVR || view === M_CALL_SIP_TRUNK) {
				dispatch(popPleaseWait(I("fetching list")))
			}
			dispatch(dispatchee)
				.then((rs) => {
					if(view === M_TAG || view === M_API_CALLBACK || view === M_API_ACCESSTOKENS || view === M_CALL_IVR || view === M_CALL_SIP_TRUNK) {
						dispatch(clearPopWaiting())
					}
					if (afterDispatchee) dispatch(afterDispatchee);
				})
				.catch(err => {
					if (process.env.NODE_ENV !== 'production') {
						console.log("error load admin list:", { err, view });
					}
					return dispatch(popErrorOnly(err));
				})
				.catch(() => {
					if(view === M_TAG || view === M_API_CALLBACK || view === M_API_ACCESSTOKENS || view === M_CALL_IVR || view === M_CALL_SIP_TRUNK) {
						dispatch(clearPopWaiting())
					}
				});
		}
	}
}

function validateService(type, val) {
	if (!addressValidation(type, val)) {
		return false;
	} else {
		return true;
	}
}

const routingWarning = I("Routing keyword contains hidden space or character")
	, correctBttn = 1
	, saveBttn = 2
	, cancelBttn = 3
	, correctBttnValue = {
		type: correctBttn
		, color: 'blue'
		, text: I('Correct and save')
	}
	, saveBttnValue = {
		type: saveBttn
		, color: 'grey'
		, text: I('Save anyway')
	}
	, cancelBttnValue = {
		type: cancelBttn
		, color: 'grey'
		, text: I('Cancel')
	}
	, askYesOrNoButtons = [
		correctBttnValue
		, saveBttnValue
		, cancelBttnValue
	]
	;

const saveRoutingKeywords = (sstAsyncs, adminView, param) => dispatch => {
	param = { params: JSON.stringify(param) };
	return dispatch(sstAsyncs[adminView].save(param))
		.then(rs => {
			dispatch(toggleAdminEdit(0, false));
			dispatch(fetchAdminList(adminView));
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error routeKeywords:", err);
			dispatch(adminActionStatus({ status: 3, msg: err }));
		});
}

export const openEditForm = (view, id) => {
	if (id === 0) {
		return resetAdminInput();
	} else if (id > 0) {
		return sstAsyncs[view].one(id);
	}
	return invalidIdAlert(id);
}

const adminSaveHandler = (
	view
	, createNew
	, id
	, input
	, reloadList
) => dispatch => dispatch(sstAsyncs[view].save(createNew, id, input))
	.then(rs => {
		dispatch(toggleAdminEdit(0, false));
		dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		if (reloadList) {
			return dispatch(fetchAdminList(view));
		}
		return rs;
	})
	.catch((err) => {
		dispatch(popErrorOnly(err));
		dispatch(adminActionStatus({ status: 3, msg: err }));
	});

export const adminAccountSaveHandler = (adminView, param, formType) => dispatch => {
	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
	let accountsAsync = accountsAsyncs[adminView].save;
	switch (formType) {
		case FORM_CREATEGROUP:
			accountsAsync = accountsAsyncs[adminView].createGroup;
			break;
		case FORM_MANAGEGROUP:
			accountsAsync = accountsAsyncs[adminView].manageGroup;
			break;
		default:
			break;
	}
	//tk-todo: add logic for account groups saving here using form State
	dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
	return	dispatch(accountsAsync(param))
		.then((rs) => {
			dispatch(toggleAdminFormType(0));
			dispatch(toggleAdminEdit(0,false));
			dispatch(fetchAdminList(adminView));
			dispatch(clearPopWaiting());
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error saving account :", err)
			dispatch(clearPopWaiting());
			dispatch(adminActionStatus({ status: 3, msg: err }));
			return dispatch(popErrorOnly(err));
		});
}


//Saving/update
export const setAdminData = (
	view
	, createNew
	, id
	, input
) => (dispatch, getState) => {
	const state = getState();
	const adminSt = state.app.admin.admin;
	let cSlackEnabled = getCollabSlackEnabled(state);
	let cJiraEnabled = getCollabJiraEnabled(state);
	let cMSTeamEnabled = getCollabMSTeamEnabled(state);
	let cGoogleChatEnabled = getCollabGoogleChatEnabled(state);
	let adminInput = adminSt.input;
	let adminView = state.app.admin.ui.view;
	let activeId = adminSt.activeId;
	let data = {}, param;

	data = adminInput;

	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));

	if (adminView === ADMIN_VIEW_MAP["wordlist"]) {
		data.name = data.wordListname;
		if (data.name == "") {
			return alertEmptyInput(dispatch);
		}
		dispatch(saveWordlistData(data))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchAgentWordlist());
				}
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (view === M_REVIEW_KEYWORDS) {
		// TODO: any other new admin should follow this standard pattern
		return dispatch(adminSaveHandler(view, createNew, id, input));
	} else if (view === M_REVIEW_AGENTS) {
		return dispatch(adminSaveHandler(view, createNew, id, input, true));
	} else if (view == M_ERRANDINTERNALSTATE) {
		return dispatch(adminSaveHandler(view, createNew, id, input));
	} else if (isSST(adminView)) {
		if (adminInput.name == ""
			|| adminInput.plainContent == ""
			|| adminInput.selectedArea == "") {
			return alertEmptyInput(dispatch);
		}
		param = {
			name: adminInput.name,
			htmlContent: adminInput.htmlContent,
			itemId: activeId,
		};
		if (adminView !== M_TEMPLATE && adminView !== M_TEMPLATE_WHATSAPP) {
			let areaList = [], personalField = "personal";
			if (adminInput.selectedArea) {
				areaList = adminInput.selectedArea;
			}
			if (adminView === M_SIGNATURE || adminView === M_SALUTATION) {
				personalField = "";
			}
			param.active = true;
			param.area = areaList;
			param.personal = personalField;
			param.content = adminInput.plainContent;
			param = { params: JSON.stringify(param) };
		} else {
			let inTmpl = adminSt.interactive;
			if (typeof inTmpl.isInteractive !== 'undefined') {
				if (inTmpl.isInteractive) {
					if (inTmpl.header == "" || inTmpl.body == "" || inTmpl.footer == "") {
						return alertEmptyInput(dispatch);
					}
					if (inTmpl.types == INTERACTIVE_LIST_TYPE) {
						if (inTmpl.buttonText == "" || inTmpl.sectionTitle == "") {
							return alertEmptyInput(dispatch);
						}
						let numOpt = inTmpl.numberOption;
						for (let i = 0; i < numOpt; i++) {
							let section = inTmpl.sections[i];
							if (typeof section !== 'undefined') {
								if (section.title == "" || section.description == "") {
									return alertEmptyInput(dispatch);
								}
							}
						}
					}
					if (inTmpl.types == INTERACTIVE_BUTTON_TYPE) {
						let numOpt = inTmpl.numberOption;
						for (let i = 0; i < numOpt; i++) {
							let button = inTmpl.buttons[i];
							if (typeof button !== 'undefined') {
								if (button.title == "") {
									return alertEmptyInput(dispatch);
								}
							}
						}
					}
				}
			}
			param.area = adminInput.selectedArea.join(",")
			param.template = adminInput.plainContent;
			param.usedFor = adminView == M_TEMPLATE ? "" : "wa";
			param.interactive = JSON.stringify(adminSt.interactive);
		}
		dispatch(sstAsyncs[adminView].save(param))
			.then(rs => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["addressbook-personal"] || adminView === ADMIN_VIEW_MAP["addressbook"]) {
		const {
			addressBookName
			, addressBookEntry
			, selectedChannel
		} = adminInput;
		let personalField = 1;
		if (adminView === ADMIN_VIEW_MAP["addressbook"]) {
			personalField = 0;
		}
		param = {
			name: addressBookName,
			address: addressBookEntry,
			service: selectedChannel,
			entry: activeId,
			personal: personalField,
		};

		if (param.name == "" || param.address == "" || param.service < 1) {
			return alertEmptyInput(dispatch);
		}

		if (!validateService(param.service, param.address)) {
			dispatch(togglePopAlert(I("* Please insert correct contact address")));
			dispatch(adminActionStatus({ status: 3, msg: I("* Please insert correct contact address") }));
			return;
		}

		let dispatchee;
		if (activeId !== 0) {
			dispatchee = updateAddressBook(param);
		} else {
			dispatchee = createNewAddressBook(param);
		}
		dispatch(dispatchee)
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchAdminList(adminView));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["externalexpert-personal"] || adminView === ADMIN_VIEW_MAP["externalexpert"]) {
		const {
			emailAddress
			, selectedArea
			, selectedChannel
			, avatarPreview
			, questionType
		} = adminInput;

		if (emailAddress == "" || selectedArea == "") {
			return alertEmptyInput(dispatch);
		}

		let checkEmail = true;
		if ((cSlackEnabled && selectedChannel === Workflow.Errand.SERVICE_SLACK) ||
			(cJiraEnabled && selectedChannel === Workflow.Errand.SERVICE_JIRA) ||
			(cMSTeamEnabled && selectedChannel === Workflow.Errand.SERVICE_MICROSOFT_TEAMS) ||
			(cGoogleChatEnabled && selectedChannel === Workflow.Errand.SERVICE_GOOGLECHAT)) {
			checkEmail = false;
		}
		if (checkEmail &&
			!validateService(Workflow.Errand.SERVICE_EMAIL, emailAddress)) {
			dispatch(togglePopAlert(I("* Please insert correct contact address")));
			dispatch(adminActionStatus({ status: 3, msg: I("* Please insert correct contact address") }));
			return;
		}

		let serviceType = selectedChannel
		if (selectedChannel == "") {
			serviceType = Workflow.Errand.SERVICE_EMAIL;
		}
		var fd = new FormData();
		fd.append("expertId", activeId);
		fd.append("emailAddress", emailAddress);
		fd.append("questionType", questionType);
		fd.append("serviceType", serviceType);
		if (adminView === ADMIN_VIEW_MAP["externalexpert"]) {
			fd.append("personal", false);
		} else {
			fd.append("personal", true);
		}
		fd.append("selectedArea", selectedArea);
		if (avatarPreview.length > 200) {
			var i = 0;
			var rand = Math.floor(Math.random() * 6) + '' + i + '' + Math.floor('' + new Date() / 1000);
			var blob = dataURItoBlob(avatarPreview);
			fd.append("uploadedAvatar", blob);
			fd.append("localName", rand);
		}
		param = fd;
		dispatch(saveEEAddressBooks(param))
			.then((rs) => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));

			})
			.catch(err => {
				console.log("error :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["filearchive"]) {
		const {
			fileArchiveName
			, fileArchiveDescription
			, selectedArea
			, file
		} = adminInput;
		if (fileArchiveName == "" || selectedArea == "") {
			return alertEmptyInput(dispatch);
		}
		var fd = new FormData();
		fd.append('description', fileArchiveDescription);
		fd.append('selectedAreas', selectedArea);
		fd.append('id', activeId);
		if (activeId == 0) {
			fd.append('file', file);
		}
		param = fd;
		dispatch(saveFileArchive(param))
			.then((rs) => {
				dispatch(toggleAdminEdit(0,false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error fileArchive :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	}else if(adminView === M_ACCOUNTS || adminView === M_ACC_EMAIL){
		const {
			contentTransformation
			, contentTransformationSubject
			, emailAddressSearchTerm
			, enabled
			, host
			, id
			, name
			, typeId
			, username
			, wantSSL
			, selectedArea
			, connectedArea //previous area before edit
			, password
			, retainFetchedEmail
			, allowImapDelete
			, fetchSeenMessages
			, allowDelEmptyEmail
			, centionDelete
			, readTimeout
			, useSecureMessage
		} = adminInput;


		var fd = new FormData();
		if(activeId == 0){
			fd.append( 'id', 0);
		}else{
			fd.append( 'id', id);
		}
		if(typeof password !== "undefined" && password !== "") {
			fd.append( 'password', password);
		}
		fd.append( 'contentTransformation', contentTransformation || "");
		fd.append( 'contentTransformationSubject', contentTransformationSubject ||"");
		fd.append( 'emailAddressSearchTerm', emailAddressSearchTerm || []);
		fd.append( 'enabled', enabled);
		fd.append( 'host', host);
		fd.append( 'name', name);
		fd.append( 'typeId', typeId);
		fd.append( 'username', username);
		fd.append( 'wantSSL', wantSSL);
		fd.append( 'connectedArea', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'retainFetchedEmail', retainFetchedEmail);
		fd.append( 'allowImapDelete', allowImapDelete);
		fd.append( 'fetchSeenMessages', fetchSeenMessages);
		fd.append( 'allowDelEmptyEmail', allowDelEmptyEmail);
		fd.append( 'centionDelete', centionDelete);
		fd.append( 'readTimeout', (readTimeout || 0));
		fd.append( 'useSecureMessage', useSecureMessage);

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_LINE){
		const {
			channelID
			, channelSecret
			, enable
			, id
			, name
			, token
			, selectedArea
			, connectedArea //previous area before edit
		} = adminInput;



		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			accountId: id,
			accountName: name,
			activated: enable,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			channelID: channelID,
			channelSecret: channelSecret,
			token: token
		}
		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_LINKEDIN){
		const {
			companyId
			, companyName
			, enable
			, id
			, name
			, selectedArea
			, connectedArea //previous area before edit
		} = adminInput;

		//
		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			enable: enable,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			companyId: companyId,
			companyName: companyName
		}
		//
		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_JIRA){
		const {
			id
			, name
			, selectedArea
			, connectedArea //previous area before edit
			, enable
			, cloud
			, accountId
			, accountName
			, token
			, jiraUrl
			, userName
			, userAccountID
			, type
			, priority
		} = adminInput;

		//
		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			activated: enable,
			cloud,
			accountId,
			accountName,
			token,
			jiraUrl,
			userName,
			userAccountID,
			type,
			priority
		}
		//
		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_HUBSPOT){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea
			, ownerEmail
			, ownerName
			, accountName
		} = adminInput;

		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			enabled: true,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea)
			//todo : check if accountName, ownerName/Email needed as well
		}
		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_MSTEAMS){
		let formType = adminSt.formType;

		if(formType === FORM_CREATEGROUP) {
			const {
				mstId
				, gname
				, desc
				, nickname
			} = adminInput

			var fd = new FormData();
			fd.append( 'mstId', mstId);
			fd.append( 'gname', gname);
			fd.append( 'desc', desc);
			fd.append( 'nickname', nickname);
			param = fd;
		} if(formType === FORM_MANAGEGROUP) {
			const {
				mstId
				, selectedGroup
				, selectedChannel
				, selectedContacts
			} = adminInput

			var fd = new FormData();
			fd.append( 'mstId', mstId);
			fd.append( 'groupId', selectedGroup);
			fd.append( 'channelId', selectedChannel);
			fd.append( 'contacts', selectedContacts);
			param = fd;
		} else {
		const {
			id
			// , accountId
			, selectedArea
			, connectedArea //previous area before edit
			, enable
			, name
			, appId
			, tenantId
			, secretId
		} = adminInput

		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			enabled: enable,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			appId: appId,
			tenantId: tenantId,
			secretId: secretId
		}
		param = { params: JSON.stringify(param) };
		}
		return dispatch(adminAccountSaveHandler(adminView, param, formType));
	}else if(adminView === M_ACC_SMPP){
		const {
			id
			, name
			, hostAddress
			, portNumber
			, loginName
			, password
			, selectedRole
			, systemType
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'hostAddress', hostAddress);
		fd.append( 'portNumber', portNumber);
		fd.append( 'loginName', loginName);
		fd.append( 'password', password);
		fd.append( 'selectedRole', selectedRole);
		fd.append( 'systemType', systemType);
		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_TRUSTPILOT){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			// , accountName
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		// fd.append( 'accountName', accountName); //todo: checkback if accountName needed

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_TELEGRAM){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea
			, accountId
			, accountName
			, token
		} = adminInput;

		//
		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			activated: enable,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			accountId: accountId,
			accountName: accountName,
			token: token
		}
		//
		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_WHATSAPP){
		let formType = adminSt.formType;

		if(formType === FORM_CREATEGROUP) {
			const {
				waId
				, gname
			} = adminInput

			var fd = new FormData();
			fd.append( 'waId', waId);
			fd.append( 'gname', gname);
			param = fd;
		} if(formType === FORM_MANAGEGROUP) {
			const {
				waId
				, selectedGroup
				, selectedContacts
			} = adminInput

			var fd = new FormData();
			fd.append( 'waId', waId);
			fd.append( 'groupId', selectedGroup);
			fd.append( 'contacts', selectedContacts);
			param = fd;
		} else {
		const {
			id
			, accountId
			, name
			, enable
			, selectedArea
			, connectedArea
			, phone
			, expireAt
			, token
			, url
			, username
			, password
			, notificationInterval
			, api
			, waBusinessId
		} = adminInput;
		//
		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			accountId: accountId || 0,
			accountName: name,
			activated: enable,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			phoneNumber: phone,
			expireAt: expireAt,
			token: token,
			serverUrl: url,
			username: username,
			password: password,
			notificationInterval: +notificationInterval || 10,
			api: api,
			waBusinessId: waBusinessId
		}
		param = { params: JSON.stringify(param) };
		}
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_VIBER){
		const {
			id
			, name
			, enable
			, accountId
			, selectedArea
			, connectedArea
			, accountName
			, token
			, webhookUrl
		} = adminInput;

		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			name: name,
			areaId: parseInt(areaId),
			activated: enable,
			oldAreaId: parseInt(connectedArea),
			accountId: accountId,
			accountName: accountName,
			token: token,
			webhookUrl: webhookUrl
		}

		param = { params: JSON.stringify(param) };
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_SLACK){
		const {
			id
			, name
			, enabled
			, appId
			, appToken
			, selectedArea
			, connectedArea
			, webhookUrl
		} = adminInput;

		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id || 0,
			name: name,
			areaId: parseInt(areaId),
			oldAreaId: parseInt(connectedArea),
			enabled: enabled,
			appId: appId,
			appToken: appToken,
			webhookUrl: webhookUrl
		}

		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_TWILIO){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			, pid
			, twilioAccountId
			, warm
			, newAccountData
		} = adminInput

		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}
		param = {
			id: id,
			pid: pid,
			name: name,
			enable: enable, //todo: troubleshoot this field, it pops REST warning
			area: parseInt(areaId),
			aid: twilioAccountId,
			warm: warm
		};

		if(adminSt.activeId === 0) {
			param = newAccountData;
		}
		return dispatch(adminAccountSaveHandler(adminView, param));

	}else if(adminView === M_ACC_YOUTUBE){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			, path
			, channelId
			, channelName
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'video', true);
		fd.append( 'channel', false);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'path', path || "");
		fd.append( 'channelId', channelId);
		fd.append( 'channelName', channelName || "");

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_GOOGLEREVIEW){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			, path
			, channelId
			, channelName
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'path', path || "");
		fd.append( 'channelId', channelId);
		fd.append( 'channelName', channelName || "");

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_GOOGLECHAT){
		const {
			id
			, enable
			, selectedArea
			, name
			, connectedArea
			, path
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'path', path || "");
		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_GOOGLEPLAY){
		const {
			id
			, enable
			, selectedArea
			, name
			, connectedArea
			, packagename
			, path
		} = adminInput

		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'packagename', packagename);
		path && fd.append( 'path', path);
		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));

	}else if(adminView === M_ACC_FACEBOOK){
		const {
			adAccountId
			, canFetchAd
			, canFetchEvent
			, canFetchPM
			, canFetchWall
			, enable
			, id
			, name
			, pageID
			, pageName
			, selectedArea
			, connectedArea //previous area before edit
			, userAccessToken
			, accessToken
			, accountUserEmail
		} = adminInput;

		let postType = "complete";


		let areaId = selectedArea;
		if(Array.isArray(selectedArea)){
			areaId = selectedArea[0]
		}

		if(typeof userAccessToken === 'undefined' || userAccessToken === ""){
			postType = "minimal";
			param = {
				id: id,
				name: name,
				enable: enable,
				wall: canFetchWall,
				pm: canFetchPM,
				advert: canFetchAd,
				areaId: parseInt(areaId),
				events: canFetchEvent,
				oldAreaId: parseInt(connectedArea),
				// areaId: selectedArea,
			}
		} else {
			param = {
				id: id,
				name: name,
				enable: enable,
				wall: canFetchWall,
				pm: canFetchPM,
				advert: canFetchAd,
				areaId: parseInt(areaId),
				events: canFetchEvent,
				oldAreaId: parseInt(connectedArea),
				// areaId: selectedArea,
				accountUserEmail: accountUserEmail,
				pageId: pageID, //pageID
				pageName: pageName, //pagename
				accessToken: accessToken,
				userAccessToken: userAccessToken,
				adAccountId: adAccountId,
			}
		}

		param = { params: JSON.stringify(param) };
		dispatch(togglePopWaiting(pleaseWaitString(I("applying settings"))));
		dispatch(accountsAsyncs[adminView].save(param, postType))
		.then((rs) => {
			dispatch(toggleAdminEdit(0,false));
			dispatch(fetchAdminList(adminView));
			dispatch(clearPopWaiting());
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error saving facebook :", err)
			dispatch(clearPopWaiting());
			dispatch(adminActionStatus({ status: 3, msg: err }));
			return dispatch(popErrorOnly(err));
		});
	}else if(adminView === M_ACC_TWITTER){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			, accountName
			// , accountID
			, canFetchPM
			, canFetchWall
			, hashTags
			, tags
		} = adminInput


		var fd = new FormData();

		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'wall', canFetchWall);
		fd.append( 'pm', canFetchPM);
		fd.append( 'hash', hashTags);
		fd.append( 'hashStr', tags || "");
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(adminView === M_ACC_INSTAGRAM){
		const {
			id
			, name
			, enable
			, selectedArea
			, connectedArea //previous area before edit
			, accountName
			, accountID
			, userAccessToken
			, pageId
			, accessToken
		} = adminInput

		var fd = new FormData();
		if(activeId == 0){
			//when create new
			fd.append( 'id', 0);
			fd.append( 'pageId', pageId);
			fd.append( 'accessToken', accessToken);
		}else{
			fd.append( 'id', id);
		}
		fd.append( 'name', name);
		fd.append( 'enable', enable);
		fd.append( 'areaId', selectedArea);
		fd.append( 'oldAreaId', connectedArea);
		fd.append( 'accountName', accountName);
		fd.append( 'accountId', accountID);
		fd.append( 'userAccessToken', userAccessToken);
		fd.append( 'accessToken', accessToken);

		param = fd;
		return dispatch(adminAccountSaveHandler(adminView, param));
	}else if(view == M_ROUTING_KEYWORDS ) {
		const {
			content
			, type
			, selectedArea
		} = adminInput;
		if (content == "" || selectedArea == "" || type == "") {
			return alertEmptyInput(dispatch);
		}
		param = {
			content: content,
			type: type,
			areas: selectedArea,
			id: activeId
		}
		if (checkInvalidCharacter(content)) {
			dispatch(customConfirm(
				routingWarning
				, askYesOrNoButtons
			))
				.then(({ button }) => {
					if (button === correctBttn) {
						const newParam = update(param, {
							content: { $set: removeInvalidCharacter(content) }
						});
						dispatch(saveRoutingKeywords(sstAsyncs, adminView, newParam));
					} else if (button === saveBttn) {
						dispatch(saveRoutingKeywords(sstAsyncs, adminView, param));
					} else if (button === cancelBttn) {
						return;
					}
				});
		} else {
			dispatch(saveRoutingKeywords(sstAsyncs, adminView, param));
		}
	} else if (view === M_BLACKLIST) {
		const { content, selectedArea } = adminInput;
	
		if (content === "" || selectedArea === "" ) {
			return alertEmptyInput(dispatch);
		}

		var fd = new FormData();
		fd.append('content', content);
		fd.append('selectedAreas', selectedArea);
		fd.append('id', activeId);
		
		param = fd;
		dispatch(saveBlacklist(param))
			.then((rs) => {
				dispatch(toggleAdminEdit(0,false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error BlackList :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	}
	else if (view === M_BLACKLISTRESP) {
		const {selectedArea, selectedLanguage, response } = adminInput;
	
		if (selectedArea === "" || selectedLanguage == 0 || response === "") {
			return alertEmptyInput(dispatch);
		}

		var fd = new FormData();
		fd.append('selectedArea', selectedArea);
		fd.append('language', selectedLanguage);
		fd.append('response', response);
		fd.append('id', activeId);
		
		param = fd;
		dispatch(saveBlacklistResp(param))
			.then((rs) => {
				dispatch(toggleAdminEdit(0,false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error BlackList response :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	}	else if (view === M_LLM_TOOL_MANAGER) {
		const { toolName, toolDescription, selectedArea, parameters
			} = adminInput;

		if (toolName === "" || selectedArea === "") {
			return alertEmptyInput(dispatch);
		}
	
		const fd = new FormData();
		fd.append('toolName', toolName);
		fd.append('toolDescription', toolDescription);
		fd.append('selectedArea', selectedArea);
		fd.append('id', activeId);

		parameters.forEach((param, index) => {
			fd.append(`parameters[${index}][name]`, param.name || "");
			fd.append(`parameters[${index}][type]`, param.type || "");
			fd.append(`parameters[${index}][description]`, param.description || "");
			fd.append(`parameters[${index}][enum]`, param.enum || "");
			fd.append(`parameters[${index}][internal]`, param.internal ? "true" : "false");
		});
	
		dispatch(saveToolManager(fd))
			.then((rs) => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("Error in ToolManager:", err);
				dispatch(adminActionAlert({
					show: true,
					msg:  I("Tool save failed. Please try again later or contact our customer support"),
					type: "error"
				}));
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (view == M_ROUTING_AUTOTAGS) {
		const {
			content
			, type
			, selectedArea
			, tagLevel
			, tagVIPColor
		} = adminInput;
		if (content == "" || selectedArea == "" || type == "" || tagLevel == "") {
			return alertEmptyInput(dispatch);
		}
		param = {
			content: content,
			type: type,
			areas: selectedArea,
			id: activeId,
			tagLevel: tagLevel,
			tagVIPColor: tagVIPColor
		}
		param = { params: JSON.stringify(param) };
		dispatch(sstAsyncs[adminView].save(param))
			.then(rs => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error routeAutoTags:", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (view == M_ROUTING_SIP) {
		const {
			content
			, type
			, selectedArea
			, routeSipId
		} = adminInput;
		if (selectedArea == "") {
			return alertEmptyInput(dispatch);
		}
		let areas = []
		if (Array.isArray(selectedArea) == false) {
			areas.push(selectedArea);
		} else {
			areas.push(selectedArea[0]);
		}
		param = {
			areas: areas,
			id: activeId,
			routeSipId: routeSipId
		}
		param = { params: JSON.stringify(param) };
		dispatch(sstAsyncs[adminView].save(param))
			.then(rs => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error routeSip:", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});

	} else if (adminView === M_ROUTING_GROUPS){
		const {
			name
			, description
			, currentAreas
		} = adminInput
		const id = adminSt.activeId;
		var fd = new FormData();
		fd.append( 'id', id);
		fd.append( 'name', name);
		fd.append( 'description', description);
		fd.append( 'areas', JSON.stringify(currentAreas));
		param = fd;
		return dispatch(sstAsyncs[adminView].save(param))
			.then(rs => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error routing groups:", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["companies"]) {
		if (activeId > 0) {
			data.id = activeId;
		}
		if (data.companyName == "") {
			return alertEmptyInput(dispatch);
		}
		dispatch(saveNewCompanies(data))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchCompanyList());
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["stunturn"]) {
		if (adminInput.stunturnname == "" ||
			adminInput.stunturnservertype == "" ||
			adminInput.stunturnurl == "") {
			dispatch(togglePopAlert(INPUT_EMPTY_WARNING));
			dispatch(adminActionStatus({ status: 3, msg: INPUT_EMPTY_WARNING }));
		}
		let param = {};
		param.id = activeId
		param.stunturnname = adminInput.stunturnname;
		param.stunturnurl = adminInput.stunturnurl;
		param.stunturnservertype = adminInput.stunturnservertype;
		param.selectedWebRtcService = adminInput.selectedWebRtcService;
		if (typeof adminInput.stunturnusername !== undefined &&
			adminInput.stunturnusername !== "") {
			param.stunturnusername = adminInput.stunturnusername;
		}
		if (typeof adminInput.stunturnpassword !== undefined &&
			adminInput.stunturnpassword !== "") {
			param.stunturnpassword = adminInput.stunturnpassword;
		}
		dispatch(saveNewStunTurn(param))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false, false, true));
					dispatch(fetchStunTurnList());
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === M_CHATWIDGETDL) {
		if (activeId > 0) {
			data.id = activeId;
		}
		if (data.baseURL == "" || data.activeAreas == "" || data.name == "") {
			return alertEmptyInput(dispatch);
		} else if (data.translation != "") {
			let translation = "{" + data.translation + "}";
			if (!isJsonStringObj(translation)) {
				dispatch(togglePopAlert(INVALID_JSON_ERROR));
				dispatch(adminActionStatus({ status: 3, msg: INVALID_JSON_ERROR }));
				return;
			}
		}
		//Verify widget position for modern skin
		if(data.skin === "modern") {
			if(data.widgetPosition === "centerLeft" || data.widgetPosition === "centerRight") {
				return alertEmptyInput(dispatch, I("Widget position"));
			}
		}
		param = { params: JSON.stringify(data) };
		dispatch(saveNewChatWidgetConfig(param))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false, false, true));
					dispatch(fetchChatWidgetCfgList());
					dispatch(fetchChatWidgetCfgDefList({ id: 0 }));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === M_FAQWIDGETDL) {
		if (activeId > 0) {
			data.id = activeId;
		}
		if (data.baseURL == "" || data.activeAreas == "" || data.activeLibraries == "") {
			return alertEmptyInput(dispatch);
		} else if (data.translationFAQ != "") {
			let translation = "{" + data.translationFAQ + "}";
			if (!isJsonStringObj(translation)) {
				dispatch(togglePopAlert(INVALID_JSON_ERROR));
				dispatch(adminActionStatus({ status: 3, msg: INVALID_JSON_ERROR }));
				return;
			}
		}
		param = { params: JSON.stringify(data) };
		dispatch(saveNewFaqWidgetConfig(param))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false, false, true));
					dispatch(fetchFaqWidgetCfgList());
					dispatch(fetchFaqWidgetCfgDefList({ id: 0 }));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === M_VOICEWIDGETDL) {
		if (activeId > 0) {
			data.id = activeId;
		}
		if (data.baseURL == "" || data.activeAreas == "" || data.name == "") {
			return alertEmptyInput(dispatch);
		} else if (data.translation != "") {
			let translation = "{" + data.translation + "}";
			if (!isJsonStringObj(translation)) {
				dispatch(togglePopAlert(INVALID_JSON_ERROR));
				dispatch(adminActionStatus({ status: 3, msg: INVALID_JSON_ERROR }));
				return;
			}
		}
		param = { params: JSON.stringify(data) };
		dispatch(saveNewVoiceWidgetConfig(param))
			.then((rs) => {
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false, false, true));
					dispatch(fetchVoiceWidgetCfgList());
					dispatch(fetchVoiceWidgetCfgDefList({ id: 0 }));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === ADMIN_VIEW_MAP["agents"]) {
		let prefMode = state.app.admin.agent.prefMode;
		dispatch(AgentValidateExternalID(prefMode));
	} else if (adminView === M_AGENTSIPSTATUS) {
		const {
			name
			, internalName
			, className
			, active
		} = adminInput;
		if (name == "" || internalName == "") {
			return alertEmptyInput(dispatch);
		} else if (internalName.includes(" ")) {
			dispatch(adminActionStatus({ status: 3, msg: INPUT_SPACE_WARNING }));
			dispatch(togglePopAlert(INPUT_SPACE_WARNING));
			return;
		}
		dispatch(validateAgentSipStatus({ id: activeId, name }))
			.then(result => {
				if (!result.valid) {
					dispatch(togglePopAlert(DUPLICATE_SIP_STATUS_NAME));
					dispatch(adminActionStatus({ status: 3, msg: DUPLICATE_SIP_STATUS_NAME }));
					return Promise.reject(result);
				}
				return dispatch(sstAsyncs[adminView].save({
					name: name,
					internalName: internalName,
					className: className,
					id: activeId,
					active: active
				}))
			})
			.then(rs => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error update agent sip status:", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else if (adminView === M_CLASSIFIER) {
		const {
			name
			, model
			, selectedArea
		} = adminInput;
		if (name == "" || model == "") {
			return alertEmptyInput(dispatch);
		} else if (model.includes(" ")) {
			dispatch(togglePopAlert(INPUT_SPACE_WARNING));
			dispatch(adminActionStatus({ status: 3, msg: INPUT_SPACE_WARNING }));
			return;
		}
		param = {
			name: name,
			model: model,
			selectedArea: selectedArea.join(),
			id: activeId,
		}
		dispatch(sstAsyncs[adminView].save(param))
		.then(rs => {
			dispatch(toggleAdminEdit(0, false));
			dispatch(fetchAdminList(adminView));
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error update agent sip status:", err);
			dispatch(adminActionStatus({ status: 3, msg: err }));
		});
	} else if(adminView === M_QUICK_REPLY) {
		const {
			name
			, selectedArea
		} = adminInput;
		if(name === "" || adminInput.selectedArea === ""){
			return alertEmptyInput(dispatch);
		}
		param = {
			name: name,
			selectedArea: selectedArea.join(),
			id: activeId,
		}
		let qr = adminSt.quickReply;
		let data = qr.msgData;
		if(typeof data !== 'object'){
			dispatch(togglePopAlert(INVALID_JSON_ERROR));
			dispatch(adminActionStatus({ status: 3, msg: INVALID_JSON_ERROR }));
			return;
		}
		if( data ){
			if(data.title === ""){
				return alertEmptyInput(dispatch);
			}
			if(data.contentType === QUICK_REPLY_MESSAGE_TEXT){
				param.usedFor = 'qr'
				let numOpt = data.numberOption;
				for (let i = 0; i < numOpt; i++) {
					let ml = data.messageList[i];
					if (typeof ml !== 'undefined') {
						if (ml.title == "") {
							return alertEmptyInput(dispatch);
						}
					}
				}
			}
		}
		param.area = adminInput.selectedArea.join(",")
		param.quickReply = JSON.stringify(data);
		dispatch(sstAsyncs[adminView].save(param))
		.then(rs => {
			dispatch(toggleAdminEdit(0, false));
			dispatch(fetchAdminList(adminView));
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error update quick reply status:", err);
			dispatch(adminActionStatus({ status: 3, msg: err }));
		});
	} else if (adminView === M_TAG) {
		const {
			tagName
			, tagVIP
			, tagDelete
			, selectedArea
			, tagColor
			, level
			, parentId
			, creatingNewSubTagMode
			, connectedTags
			, newSubTags

		} = adminInput;
		param = {
			tagId: activeId,
			tagName,
			tagVIP,
			tagColor,
			tagDelete,
			tagLevel: level,
			selectedAreas: selectedArea,
			parentId: (level > 1 ? parentId : 0)
		};
		param = { params: JSON.stringify(param) };

		if (tagName == "" || selectedArea.length === 0) {
			return alertEmptyInput(dispatch);
		}
		const dispatchee = adminTagSave(param);
		dispatch(popPleaseWait(I("saving tag")));
		dispatch(dispatchee)
			.then((rs) => {
				let editMode = false;
				dispatch(clearPopWaiting())
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					const tagList = tagsListSelector(state);
					if(newSubTags.length > 0) {
						const params = {
							"id": rs.tagLevelId
							, "tags": newSubTags
							, "addToArea": true
						};
						const param = { params: JSON.stringify(params) };
						editMode = true;
						dispatch(adminTagAppend(param, adminView, true));
					} else {
						if(rs.tagLevelId) {
							dispatch(setAdminInput("parentId", rs.tagLevelId));
							dispatch(setAdminInput("level", level+1));
							dispatch(setAdminInput("tagName", ""));
						}
					}
					if(!creatingNewSubTagMode) {
						if(editMode) {
							dispatch(toggleAdminEdit(rs.tagLevelId, true, false, tagList));
						} else {
							if(level > 1) {
								//back to it's parent form
								dispatch(toggleAdminEdit(parentId, true, false, tagList));
								if(typeof tagList !== "undefined") {
									dispatch(getAdminDataFromList(parentId, tagList, adminView));
								}
							} else {
								dispatch(toggleAdminEdit(0, false));
							}
						}
					}
					dispatch(fetchAdminList(adminView));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error :", err)
				dispatch(popError(err));
				dispatch(adminActionStatus({ status: 3, msg: err }));
			})
			.catch(() => dispatch(clearPopWaiting()));
	} else if(adminView === M_API_CALLBACK) {
		const {
			cbName
			, cbEndpoint
			, cbSecretKey
			, cbEvent
			, cbEnabled
			, selectedArea
		} = adminInput;
		let areaList = "";
		if(typeof selectedArea === "object" && selectedArea.length > 0) {
			areaList = selectedArea.join(",");
		}
		param = {
			id: activeId,
			phrase: cbSecretKey,
			name: cbName,
			enabled: cbEnabled,
			type : cbEvent,
			endpoint: cbEndpoint,
			areaList
		};
		param = { params: JSON.stringify(param) };

		if (cbName == "" || cbSecretKey == "" || cbEvent == "" || cbEndpoint == "" || areaList == "") {
			return alertEmptyInput(dispatch);
		}
		const dispatchee = saveCallbackAPI(param);
		dispatch(popPleaseWait(I("saving info")));
		dispatch(dispatchee)
			.then((rs) => {
				dispatch(clearPopWaiting())
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchAdminList(adminView));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error :", err);
				dispatch(popError(err));
				dispatch(adminActionStatus({ status: 3, msg: err }));
			})
			.catch(() => dispatch(clearPopWaiting()));
	} else if (adminView === M_API_ACCESSTOKENS) {
		//saveJWTAPI
		const {
			jwtName
			, jwtKey
			, jwtAutomatedConversationUser
			, jwtACWebhookURL
			, jwtAllowAttachment
			, jwtAllowAccessLib
			, jwtProgressStatus
			, jwtErrandData
			, jwtCreateCustContact
			, jwtIntegrateAgentLogin
			, jwtAccessExportAPI
			, jwtLogioAPI
			, jwtStatsAPI
			, jwtChatMessages
			, jwtExpiryDate
			, jwtAPIVersion
			, jwtCreateErrandChannel
			, jwtCreateErrandAcc
			, jwtCreateErrandArea
		} = adminInput;

		let p = {};
		if(activeId > 0) {
			p.id = activeId;
		}
		p.params = {
			expiry: (jwtExpiryDate === "None" || jwtExpiryDate === "") ? "" : jwtExpiryDate,
			key: jwtKey,
			allowAttachment: jwtAllowAttachment,
			name: jwtName,
			apiVersion: jwtAPIVersion.toString(),
			automatedConversation: {
				user: jwtAutomatedConversationUser ? jwtAutomatedConversationUser : null,
				webhook: jwtACWebhookURL ? jwtACWebhookURL : ""
			},
			others: {
				library: jwtAllowAccessLib,
				errandStatus: jwtProgressStatus,
				errandData: jwtErrandData,
				contact: jwtCreateCustContact,
				integration: jwtIntegrateAgentLogin,
				export: jwtAccessExportAPI,
				logio: jwtLogioAPI,
				statistics: jwtStatsAPI,
				chatMessages: jwtChatMessages
			},
		};
		if(jwtCreateErrandChannel) {
			p.params.createErrand = {
				channel: jwtCreateErrandChannel,
				area: jwtCreateErrandArea ? jwtCreateErrandArea : 0,
				account: jwtCreateErrandAcc ? jwtCreateErrandAcc : 0
			}
		}
		param = JSON.stringify(p);

		if(activeId === 0) {
			if(!jwtName || !jwtKey) {
				return alertEmptyInput(dispatch);
			}
		} else {
			//edit
			if(jwtKey !== "") {
				if(jwtName == "") {
					return alertEmptyInput(dispatch);
				}
			}
		}
		if(jwtCreateErrandChannel > 0) {
			//warn channel dependency
			if(!jwtCreateErrandArea && !jwtCreateErrandAcc) {
				return alertEmptyInput(dispatch);
			}
		}

		const dispatchee = saveJWTAPI(param);
		dispatch(popPleaseWait(I("saving info")));
		dispatch(dispatchee)
			.then((rs) => {
				dispatch(clearPopWaiting())
				if (rs.error) {
					dispatch(adminActionStatus({ status: 3, msg: rs.error }));
					return dispatch(togglePopAlert(rs.error));
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchAdminList(adminView));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
				}
			})
			.catch(err => {
				console.log("error :", err);
				dispatch(popError(err));
				dispatch(adminActionStatus({ status: 3, msg: err }));
			})
			.catch(() => dispatch(clearPopWaiting()));

	} else if(adminView === M_CALL_IVR) {
		const {
			ivrCallflow
			, ivrQueueMusicFile
			, ivrOffhoursMsgFile
		} = adminInput;

		let name = "", desc = "";
		if(ivrCallflow) {
			name = ivrCallflow[0].name;
			desc = ivrCallflow[0].description;
		}

		//ConnectToQueue string is a `must have` when admin selecting "Call Queue" in the IVR callflow
		const hasEmptyConnectToQueue = ivrCallflow.some(cf => (cf.actiontype == 'returnqueue' && cf.connecttoqueue == ""));
		if(hasEmptyConnectToQueue) {
			return alertEmptyInput(dispatch, I("Call queue"));
		}

		const callflow = JSON.stringify(ivrCallflow);

		let p = {
			name: name,
			description: desc,
			version: '1.0',
			afterwork_prompt: ivrOffhoursMsgFile,
			moh: ivrQueueMusicFile,
			callflow
		};
		if(activeId > 0) {
			p.id = activeId;
		}
		console.table(p);
		console.table(ivrCallflow);

		if(!name) {
			return alertEmptyInput(dispatch);
		}

		const dispatchee = saveIVRAdmin(p);

		let alertMsg = {
			show: false,
			msg: "",
			type: "error"
		}

		dispatch(popPleaseWait(I("saving info")));
		dispatch(dispatchee)
			.then((rs) => {
				dispatch(clearPopWaiting())
				if (rs.error || rs.status === "fail") {
					let alertMsg = {
						show: true,
						msg: rs.error ? rs.error : I("fail saving IVR"),
						type: "error"
					}
					dispatch(adminActionAlert(alertMsg));
					return;
				} else {
					dispatch(toggleAdminEdit(0, false));
					dispatch(fetchAdminList(adminView));
					dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
					alertMsg = {
						show: true,
						msg: name + " " + I("IVR have been saved"),
						type: "success"
					}
					dispatch(adminActionAlert(alertMsg));
				}
			})
			.catch(err => {
				console.log("error :", err);
				dispatch(popError(err));
				dispatch(adminActionStatus({ status: 3, msg: err }));
			})
			.catch(() => dispatch(clearPopWaiting()));

	} else if(adminView === M_GENERATIVE_AI_DOCUMENT) {
		const {
			docName
			, docUrl
			, docDescription
			, selectedLanguage
			, selectedArea //array
			, file
			, docType
		} = adminInput;
		if ((docName == "" && docUrl == "") || selectedArea == "" || selectedLanguage == 0) {
			return alertEmptyInput(dispatch);
		}
		var fd = new FormData();
		if (docUrl !== "") {
			fd.append('url', docUrl);
		}

		if (docName !== "" && docType !== "URL") {
			fd.append('file', file);
		}
		fd.append('description', docDescription);
		fd.append('language', selectedLanguage);
		fd.append('selectedAreas', selectedArea);
		if(activeId > 0) {
			fd.append('id', activeId);
		}
		param = fd;
		dispatch(postGenerativeAIDoc(param))
			.then((rs) => {
				dispatch(toggleAdminEdit(0, false));
				dispatch(fetchAdminList(adminView));
				dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
			})
			.catch(err => {
				console.log("error save generative ai document :", err);
				dispatch(adminActionStatus({ status: 3, msg: err }));
			});
	} else {
		if (process.env.NODE_ENV !== 'production') {
			console.log("no dispatcher yet for ", adminView);
		}
	}
}

//Remove
export const removeAdminData = (field, view) => (dispatch, getState) => {
	const state = getState()
		, uid = getMyId(state)
		, activeId = getActiveAdminId(state)
		, adminView = getCurrentAdminView(state)
		, viewMap = ADMIN_VIEW_MAP
		;
	let data = field.id
		, removeOpenedData = false
		, dispatchee
		, afterDispatchee
		, afterDispatchee2
		;
	if (activeId === data) {
		removeOpenedData = true;
	}
	if (adminView === viewMap["wordlist"]) {
		dispatchee = removeWordlist(data);
		afterDispatchee = fetchAgentWordlist();
	} else if (isSST(adminView)) {
		dispatchee = sstAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	} else if (view === M_REVIEW_KEYWORDS || view === M_REVIEW_AGENTS) {
		dispatchee = sstAsyncs[adminView].remove(data);
	} else if (adminView === viewMap["addressbook-personal"] || adminView === viewMap["addressbook"]) {
		let personalField = 1;
		if (adminView === viewMap["addressbook"]) {
			personalField = 0;
		}
		data = {
			entry: field.id,
			personal: personalField
		}
		dispatchee = deleteAddressBook(data);
		afterDispatchee = fetchAdminList(adminView)
	} else if (adminView === viewMap["externalexpert-personal"] || adminView === viewMap["externalexpert"]) {
		let personalField = true;
		if (adminView === viewMap["externalexpert"]) {
			personalField = false;
		}
		data = {
			expertId: field.id,
			personal: personalField
		}
		dispatchee = removeEEAddressBooks(data);
		afterDispatchee = fetchAdminList(adminView)
	} else if (adminView === viewMap["filearchive"]) {
		data = {
			id: field.id
		}
		dispatchee = removeFileArchive(data);
		afterDispatchee = fetchAdminList(adminView);

	} else if (isAccount(view)) {
		data = {
			id: field.id,
			area: field.selectedArea
		}
		dispatchee = accountsAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	} else if (view === M_ROUTING_KEYWORDS || view === M_ROUTING_AUTOTAGS ||
		view == M_ROUTING_SIP) {
		data = {
			id: field.id
		}
		dispatchee = sstAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	} else if (view === M_BLACKLIST) {
		data = {
			id: field.id
		}
		dispatchee = sstAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	}else if (view === M_LLM_TOOL_MANAGER) {
		data = {
			id: field.id
		}
		dispatchee = sstAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	}else if (view === M_BLACKLISTRESP) {
		data = {
			id: field.id
		}
		dispatchee = sstAsyncs[adminView].remove(data);
		afterDispatchee = fetchAdminList(adminView);
	}else if (adminView === viewMap["companies"]) {
		data = {
			id: field.id
		}
		dispatchee = removeCompany(data);
		afterDispatchee = fetchAdminList(adminView);
	} else if (adminView === M_CHATWIDGETDL) {
		data = {
			id: field.id
		}
		dispatchee = removeChatWidgetCfg(data);
		dispatch(toggleAdminEdit(0, false, false, true));
		afterDispatchee = fetchAdminList(adminView);
		removeOpenedData = false;
	} else if (adminView === M_FAQWIDGETDL) {
		data = {
			id: field.id
		}
		dispatchee = removeFaqWidgetCfg(data);
		dispatch(toggleAdminEdit(0, false, false, true));
		afterDispatchee = fetchAdminList(adminView);
		removeOpenedData = false;
	} else if (adminView === M_VOICEWIDGETDL) {
		data = {
			id: field.id
		}
		dispatchee = removeVoiceWidgetCfg(data);
		dispatch(toggleAdminEdit(0, false, false, true));
		afterDispatchee = fetchAdminList(adminView);
		removeOpenedData = false;
	} else if (adminView === M_AGENTSIPSTATUS) {
		dispatchee = sstAsyncs[adminView].remove({
			id: field.id,
			active: false
		});
		afterDispatchee = fetchAdminList(adminView);
	} else if (adminView === M_CLASSIFIER) {
		dispatchee = sstAsyncs[adminView].remove({
			id: field.id,
		});
		afterDispatchee = fetchAdminList(adminView);
	} else if (adminView === M_STUN_TURN) {
		data = {
			id: field.id
		}
		dispatchee = removeStunTurn(data);
		afterDispatchee = fetchAdminList(adminView);
	}else if (adminView === M_QUICK_REPLY) {
		data = {
			id: field.id
		}
		dispatchee = removeQuickReply(data.id);
		afterDispatchee = fetchAdminList(adminView);
	}else if (adminView === M_TAG) {
		data = {
			tagId: field.id,
		}
		dispatchee = removeTagFromList(data);
		afterDispatchee = fetchAdminList(adminView);
		if(activeId > 0) {
			afterDispatchee2 = removeSubTags(field.id);
		}
	}else if(adminView === M_API_CALLBACK) {
		dispatchee = removeCallbackAPI(field.id);
		afterDispatchee = fetchAdminList(adminView);
	}else if(adminView === M_API_ACCESSTOKENS) {
		data = {
			id: field.id,
		}
		dispatchee = removeJWTAPI(data);
		afterDispatchee = fetchAdminList(adminView);
	}else if(adminView === M_CALL_IVR) {
		dispatchee = deleteIVRAdmin(field.id);
		afterDispatchee = fetchAdminList(adminView);
	}else if(adminView === M_CALL_SIP_TRUNK) {
			//TODO
		console.log("remove sip trunk");

	} else if (adminView === M_GENERATIVE_AI_DOCUMENT) {
		data = {
			id: field.id
		}
		dispatchee = deleteGenerativeAIDocument(data) // removeGenerativeAIDocument(data);
		afterDispatchee = fetchAdminList(adminView);

	}else {
		if (process.env.NODE_ENV !== 'production') {
			console.log("no dispatcher yet for ", adminView);
		}
	}
	if (dispatchee) {
		if(adminView === M_TAG) {
			dispatch(popPleaseWait(I("removing tags"))); //todo: do this for all admin page based on view
		} else if(adminView === M_API_CALLBACK) {
			dispatch(popPleaseWait(I("removing data")));
		}
		dispatch(dispatchee)
			.then((rs) => {
				if(adminView === M_TAG || adminView === M_API_CALLBACK) {
					dispatch(clearPopWaiting())
				} else if(adminView === M_CALL_IVR) {
					const alertMsg = {
						show: true,
						msg: I("IVR have been removed"),
						type: "success"
					}
					dispatch(adminActionAlert(alertMsg));
				}
				if (afterDispatchee) dispatch(afterDispatchee);
				if (afterDispatchee2) dispatch(afterDispatchee2);
				if (removeOpenedData) {
					dispatch(toggleAdminEdit(0, false, false));
				}
			})
			.catch(err => {
				console.log("error : ", err);
				dispatch(popError(err));
			})
			.catch(() => {
				if(adminView === M_TAG || adminView === M_API_CALLBACK) {
					dispatch(clearPopWaiting());
				}
			});
	}
};

export const removeEEAvatar = (id) => (dispatch, getState) => {
	if (id && id > 0) {
		dispatch(doRemoveEEAvatar({ id: id }))
			.then((rs) => {
			})
			.catch(err => {
				console.log("error : ", err);
			});
	} else {
		dispatch(setAdminInput("avatarPreview", ""));
	}
}

const checkingUploadAgentStatus = (p) => async(checkAgentImportStatus(p),
	admin[keyAgentImportStatus]
);

const agentCheckImportStatus = () => (dispatch, getState) => {
	const adminState = getState().app.admin;
	const checkStatus = dispatch(checkingUploadAgentStatus())
		.then((rs) => {
			if (rs.state.length > 0) {
				dispatch(uploadAgentInProgress(UPLOAD_AGENT_INPROGRESS));
			} else {
				dispatch(stopRunAgentImportStatus());
				dispatch(uploadAgentInProgress(UPLOAD_AGENT_FINISH));

				let typeFilter = adminState.admin.filter.agentTypeFilter, searchTxt = adminState.admin.filter.wordFilter;
				let params = { searchText: searchTxt, filter: typeFilter };
				dispatch(agentList(params));
			}
		})
	const promises = [checkStatus];
	return Promise.all(promises);
};

const runAgentImportStatus = periodicPoll(agentCheckImportStatus(), TMR_AGENT_IMPORT_STATUS);

export const stopRunAgentImportStatus = runAgentImportStatus.stop;

const startRunAgentImportStatus = runAgentImportStatus.start;

export const doDownloadFileArchive = id => downloadFileArchive(id)

//IVR
export const fetchIVRAdminList = (p) => async(getIVRList(p), admin[keyCallIVRList]);

export const fetchSipTrunkList = (p) => async(getSipTrunkList(p), admin[keyCallSipTrunkList]);

export const fetchSipPrioNumberList = (p) => async(getSipPriorityNumbers(p), admin[keyGetSipPriorityNumbers]);

export const saveIVRAdmin = (p) => async(saveIVR(p), admin[keyCallIVRSave]);

export const deleteIVRAdmin = (id) => async(removeIVR(id), admin[keyCallIVRDelete]);

export const uploadIVRPromptFile = (p) => async(uploadIVRPrompt(p), admin[keyCallIVRPromptUpload]);

export const uploadIVRMOHFile = (p) => async(uploadIVRMOH(p), admin[keyCallIVRMOHUpload]);

export const doDeployIVRAdmin = (p) => async(deployIVR(p), admin[keyCallIVRDeploy]);

const warnMakeDeployIVR = I("Release this IVR ?")
	, warnBttnYes = 1
	, warnBttnNo = 2
	, yesBttnValue = {
		type: warnBttnYes
		, color: 'blue'
		, text: I('Yes')
	}
	, noBttnValue = {
		type: warnBttnNo
		, color: 'grey'
		, text: I('No')
	}
	, warnYesOrNoButtons = [
		yesBttnValue
		, noBttnValue
	]
	, shouldDeployIVR = {shouldDeployIVR: true}
	;

export const deployIVRAdmin = (p) => async (dispatch, getState) => {
	const adminState = getState().app.admin;
	const ivrName = adminState.admin.input.ivrCallflow[0].name || "";
	try {
		const deployIVR = await new Promise((resolve, reject) => {
			resolve(dispatch(warnDeployIVR(ivrName, p)));
		});
		if (deployIVR) {
			//dispatch(fetchAdminList(M_CALL_IVR));
		}
	} catch (err) {
		console.log("Error :", err);
		return;
	}
};

const warnDeployIVR = (ivrName, p) => (dispatch, getState) => {
	new Promise((resolve, reject) => {
		let warnBttns = "";
		warnBttns = warnYesOrNoButtons;
		resolve(dispatch(customConfirm(
			warnMakeDeployIVR + " " + ivrName
			, warnBttns
		))
			.then(({ button }) => {
				if (button == warnBttnYes) {
					return true;
				} else if (button == warnBttnNo) {
					return;
				}
			}).catch(err => {
				return Promise.reject(new Error(err));
			}));
	})
	.then(shouldDeployIVR => {
		if (shouldDeployIVR) {
			dispatch(doDeployIVRAdmin(p))
			.then((rs => {
				if(rs && rs.result) {
					let alertMsg = {
						show: false,
						msg: "",
						type: ""
					}
					if(rs.result.status === "success") {
						alertMsg = {
							show: true,
							msg: ivrName + " " + I("IVR have been released"),
							type: "success"
						}
						dispatch(fetchAdminList(M_CALL_IVR));
					} else {
						alertMsg = {
							show: true,
							msg: ivrName + " " + I("IVR release fail"),
							type: "error"
						}
					}
					dispatch(adminActionAlert(alertMsg));
				}
			}))
			return true;
		} else {
			return false;
		}
	});
}

export const uploadAddressBookList = (files) => (dispatch) => {
	dispatch(popPleaseWait(I("uploading")));
	dispatch(doUploadAddressBook(files))
	.then((rs) => {
		dispatch(clearPopWaiting())
		.then(() => {
			if(rs.error || rs.err) {
				let errorMsg = I("File uploading failed! Please contact support.");
				if(typeof rs.error === "string" || typeof rs.err === "string") {
					errorMsg = rs.error || rs.err;
				}
				const alertMsg = {
					show: true,
					msg: errorMsg,
					type: "error"
				}
				dispatch(adminActionAlert(alertMsg));
				return;
			} else {
				if(rs.status) {
					let statusMsg = I("Address list uploaded successfully.");
					if(typeof rs.status === "string") {
						statusMsg = rs.status;
					}
					const alertMsg = {
						show: true,
						msg: statusMsg,
						type: "success"
					}
					dispatch(adminActionAlert(alertMsg));
				}
				dispatch(fetchAdminList(M_ADDRESSBOOK));
			}
		})
		.catch(err => dispatch(popError(err)))
		.catch(() => dispatch(clearPopWaiting()))
		;
	});
}

//Skills
export const saveSkillsCategory = (p) => (dispatch) => {
	if(p.name == ""){
		return alertEmptyInput(dispatch);
	}
	dispatch(postSaveSkillsCategory(p))
		.then((rs) => {
			dispatch(fetchSkillsCategory());
			let catName = "", msg = "", type = "success";
			if(rs && rs.id) {
				dispatch(setAdminInput("skillCategoryId", rs.id));
				if(rs && rs.name) {
					catName = rs.name;
					msg = catName + " " + I("category has been saved");
				}
			} else {
				if (rs && rs.error) {
					msg = rs.error;
					type = "error";
				}
			}
			dispatch(adminActionAlert({
				show: true,
				msg,
				type
			}));
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : skillName + " " + I("category save failed"),
				type: "error"
			}));
		});
}

export const saveSkills = (p) => (dispatch) => {
	const { name , category } = p;
	if(name == "" || category == ""){
		return alertEmptyInput(dispatch);
	}
	dispatch(postSaveSkills(p))
		.then((rs) => {
			dispatch(fetchSkills());
			dispatch(toggleAdminEdit(0, false, false, true));
			let skillName = "", msg = "", type = "success";
			if(rs && rs.name) {
				skillName = rs.name;
				msg = skillName + " " + I("skill has been saved");
			} else if(rs && rs.error) {
				msg = rs.error;
				type = "error";
			}
			dispatch(adminActionAlert({
				show: true,
				msg,
				type
			}));

		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : skillName + " " + I("skill save failed"),
				type: "error"
			}));
		});
}

export const saveAgentSkill = (p) => (dispatch) => {
	const { agentId , proficiencyId } = p;
	if(agentId == null || proficiencyId == null){
		return alertEmptyInput(dispatch);
	}
	dispatch(postSaveSkillAgent(p))
		.then((rs) => {
			dispatch(fetchSkills());
			dispatch(fetchSkillAgents(rs.skillId));
			dispatch(adminActionAlert({
				show: true,
				msg:  I("Agent skill has been saved"),
				type: "success"
			}));
			const savedSkill = {
				skillId: rs.skillId,
				name: rs.name,
				proficiency: rs.proficiency,
				id: rs.id
			}
			dispatch(updateAgentSkill(savedSkill, false));
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Agent skill save failed"),
				type: "error"
			}));
		});
}

export const saveAreaSkill = (p) => (dispatch) => {
	const { areaId , skillId } = p;
	if(areaId == null || skillId == null){
		return alertEmptyInput(dispatch);
	}
	dispatch(postSaveSkillArea(p))
		.then((rs) => {
			if(rs.areaId) {
				dispatch(fetchSkillAreas(rs.areaId));
			}
			if(rs && rs.status === "success") {
				dispatch(adminActionAlert({
					show: true,
					msg:  I("Area skill has been saved"),
					type: "success"
				}));
			}
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Area skill save failed"),
				type: "error"
			}));
		});
}

export const fetchSkillsCategory = (p) => async(getSkillsCategory(p), admin[keyGetSkillsCategory]);
export const fetchSkills = (p) => async(getSkills(p), admin[keyGetSkills]);
export const fetchSkillProficiency = (p) => async(getSkillsProficiency(p), admin[keyGetSkillProficiency]);
export const fetchSkillAgents = (p) => async(getSkillAgents(p), admin[keyGetSkillAgents]);
export const fetchSkillAreas = (p) => async(getSkillAreas(p), admin[keyGetSkillAreas]);
export const removeSkillCategory = (p) => (dispatch) => {
	dispatch(deleteSkillCategory(p))
		.then((rs) => {
			dispatch(fetchSkillsCategory());
			dispatch(adminActionAlert({
				show: true,
				msg:  I("Category has been removed"),
				type: "success"
			}));
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Category remove failed"),
				type: "error"
			}));
		});
}
export const removeSkill = (p) => (dispatch) => {
	dispatch(deleteSkill(p))
		.then((rs) => {
			dispatch(fetchSkills());
			dispatch(adminActionAlert({
				show: true,
				msg:  I("Skill has been removed"),
				type: "success"
			}));
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Skill remove failed"),
				type: "error"
			}));
		});
		dispatch(toggleAdminEdit(0, false, false, true));
}
export const removeSkillAgent = (p) => (dispatch) => {
	dispatch(deleteSkillAgent(p))
		.then((rs) => {
			dispatch(fetchSkills());
			if(rs && rs.skillId) {
				dispatch(fetchSkillAgents(rs.skillId));
				dispatch(adminActionAlert({
					show: true,
					msg:  I("Agent has been removed from skill"),
					type: "success"
				}));
				const removedSkill = {
					skillId: rs.skillId,
					name: rs.name,
					proficiency: rs.proficiency,
					id: rs.id
				}
				dispatch(updateAgentSkill(removedSkill, true));
			}
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Agent remove from skill failed"),
				type: "error"
			}));
		});
}

export const removeSkillArea = (p) => (dispatch) => {
	dispatch(deleteSkillArea(p))
		.then((rs) => {
			if(rs && rs.areaId) {
				dispatch(fetchSkillAreas(rs.areaId));
				dispatch(adminActionAlert({
					show: true,
					msg:  I("Area has been removed from skill"),
					type: "success"
				}));
			}
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Area remove from skill failed"),
				type: "error"
			}));
		});
}

//agent assist
export const fetchAgentAssistConf = (p) => async(getAgentAssist(p), admin[keyAgentAssist]);
export const saveAgentAssistConf = (p) => (dispatch) => {
	dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
	dispatch(saveAgentAssist(p))
		.then((rs) => {
			dispatch(adminActionAlert({
				show: true,
				msg:  I("Agent assist customization has been saved"),
				type: "success"
			}));
			dispatch(adminActionStatus({ status: 2, msg: I("Finished") }));
		})
		.catch(err => {
			console.log("error : ", err);
			dispatch(adminActionAlert({
				show: true,
				msg:  err ? err : I("Agent assist customization save failed"),
				type: "error"
			}));
			dispatch(adminActionStatus({ status: 3, msg: err }));
		});
}
