import {
	ALERT_CONFIRM_CLOSED,
	ALERT_CONFIRM_CUSTOM_BUTTON_CLICK,
	ALERT_CONFIRM_TOGGLE,
	HEADER_ALERT_EDIT_DATA,
	SHOW_HIDE_POST_PREVIEW,
	CLEAR_POST_LINK,
	TOGGLE_HEADER_ARROW,
	TOGGLE_CHAT_WINDOW,
	TOGGLE_POP_ALERT,
	ADD_TOAST_ALERT,
	UPDATE_TOAST_LIST,
	TOGGLE_POP_WAIT,
	TOGGLE_MENU_DROPDOWN,
	TOGGLE_ERRAND_FAV_FORWARD_TO_AREA,
	TOGGLE_ERRAND_FORWARD_TO_AREA,
	ENABLE_PICK_UP_NEXT,
	TOGGLE_HOTKEYS_HELPER,
	STORE_HOTKEYS_ACTIVE,
	SETUP_HOTKEY_LIST,
	SET_HOTKEY_ACTIVE,
	ACTIVE_HOTKEY_DIALOG,
	SHOW_ALL_NOTIFICATION,
	SET_NOTIFICATION_TAB,
	SHOW_ANNOUNCEMENT,
	SHOW_CHAT_SOURCE_CONFIG,
	TOGGLE_CHAT_SOURCE,
	SHOW_POPUP_NOTIFICATION,
	HIDE_POPUP_NOTIFICATION,
	ADD_NEW_POPUP_NOTIFICATION,
	UPDATE_CHAT_CONFIG_STATUS,
} from '../constants/constants'
import {
	ALRT_CFRM_OPR_NORMAL,
	ALRT_CFRM_OPR_OPTIONAL,
	NOTIFICATION_WITHOUT_KEEP,
	PC_REJECT,
	PC_RESOLVE,
	POPBOX_AUTO_LOGOUT,
	POPBOX_CONFIRM,
	POPBOX_CUSTOM
} from '../../common/v5/constants';
import {
	DummyFunction
	, createPromiseControllers
	, pleaseWaitString
	, getExtQueueType
	, notifyOS
	, getNotificationMessage
} from '../../common/v5/helpers';
import { analyzeError } from '../../common/v5/utils';
import {
	alertMemo,
	getTotalChatChannelsSelector,
	getTotalChatChannelsActiveSelector
} from '../selectors/hmf';
import { readNotification } from '../../common/v5/socket';
import { actionReducerCreator } from '../util'

const [_headerAlertActionMap, _headerAlertReducerMap] = actionReducerCreator([
	[HEADER_ALERT_EDIT_DATA, updater => ({ data: updater })]
])

export const headerAlertReducerMap = _headerAlertReducerMap;

export const {
  [HEADER_ALERT_EDIT_DATA]: editHeaderAlertData,
} = _headerAlertActionMap

export const alertConfirmButtonClick = (button, cancel) => ({
	type: ALERT_CONFIRM_CUSTOM_BUTTON_CLICK,
	payload: {button, cancel}
});

export const toggleHeaderArrow = key => ({
	type: TOGGLE_HEADER_ARROW,
	payload: key
});

export const activateChat = toggle => ({
	type: TOGGLE_CHAT_WINDOW,
	payload: toggle
});

const [ popupPromiseStart, alertPromiseAction ] = createPromiseControllers();

function alertReject(data) {
	return alertPromiseAction(PC_REJECT, data);
}

function alertResolve(data) {
	return alertPromiseAction(PC_RESOLVE, data);
}

export const alertPromiseStart = popupPromiseStart;

const popAlert = (type, text, custom) => dispatch => {
	if (typeof text === 'undefined') {
		dispatch({type});
		return alertPromiseStart();
	}
	dispatch({type, payload: {text, custom}});
	return alertPromiseStart(true);
};

export const togglePopAlert = (text, custom) => popAlert(TOGGLE_POP_ALERT, text, custom);

export const toggleToastAlert = (type, position, text) => ({
	type: ADD_TOAST_ALERT,
	payload: ({type, position, text})
});
export const updateToastList = (list) => ({
	type: UPDATE_TOAST_LIST,
	payload: list
});

const dummyReject = () => Promise.reject();

export const popError = (err, custom) => {
	const [ isError, explanation ] = analyzeError(err, custom);
	if (isError) {
		if (process.env.NODE_ENV !== 'production') {
			console.trace("dbg: pop error trace:", explanation);
		}
		return togglePopAlert(explanation, custom);
	}
	console.trace
		&& console.trace(`can't determine '${err}' as error as because ${explanation}`);
	return dummyReject;
};

export const popErrorOnly = (err, custom) => dispatch => dispatch(popError(err, custom)).catch(DummyFunction);

export const togglePopWaiting = text => popAlert(TOGGLE_POP_WAIT, text);

export const popWaitingWithoutCatch = text => dispatch =>
	dispatch(togglePopWaiting(text)).catch(DummyFunction);

export const popPleaseWait = waitReason => popWaitingWithoutCatch(pleaseWaitString(waitReason));

export const clearPopWaiting = () => togglePopWaiting();

const confirmationBox = {
	[POPBOX_CUSTOM]: true,
	[POPBOX_CONFIRM]: true,
	[POPBOX_AUTO_LOGOUT]: true
};

export const onlyPopupIfNoConfirmBox = getState => {
	const data = alertMemo(getState());
	if (!data || !data.show || !confirmationBox[data.type]) {
		return true;
	}
};

const checkShouldPopup = (condition, getState) => {
	if (typeof condition !== 'function' || condition(getState)) {
		return true;
	}
};

export const wrapPopWait = (waitReason, thunk, condition) => (...args) => (
	dispatch,
	getState
) => {
	const popup = checkShouldPopup(condition, getState);
	if (popup) {
		dispatch(popPleaseWait(waitReason));
	}
	return dispatch(thunk(...args)).then(data => {
		if (popup) {
			dispatch(clearPopWaiting());
		}
		return data;
	}).catch(err => {
		if (checkShouldPopup(condition, getState)) {
			return dispatch(popErrorOnly(err));
		}
		return err;
	});
};

export const toggleAlertConfirm = state => ({type: ALERT_CONFIRM_TOGGLE, payload: state});

export const alertConfirmClosed = () => (dispatch, getState) => {
	const alert = getState().app.header.uiData.alert
		, { button, cancel, data, opr } = alert
		;
	if (!cancel) {
		if (opr === ALRT_CFRM_OPR_NORMAL) {
			alertResolve({button, data});
		} else if (opr === ALRT_CFRM_OPR_OPTIONAL) {
			if (data) {
				const { param, option } = data;
				alertResolve({param, option});
			} else {
				alertResolve();
			}
		} else {
			alertResolve({opr, data});
		}
	} else {
		if (opr === ALRT_CFRM_OPR_NORMAL) {
			alertReject({button, data});
		} else {
			alertReject({opr, data});
		}
	}
	dispatch({type: ALERT_CONFIRM_CLOSED});
};

const showOrHidePostPreview = show => ({
	type: SHOW_HIDE_POST_PREVIEW
	, payload: !!show
});

export const showPostPreview = () => showOrHidePostPreview(true)
	, closePostPreview = () => showOrHidePostPreview(false)
	;

export const clearPostPreview = () => {
	return {
		type: CLEAR_POST_LINK,
		payload: null
	}
}

export const showListMenuToggle = t => {
	return {
		type: TOGGLE_MENU_DROPDOWN,
		payload: t
	}
}

export const enablePickUpNext = (status) => {
	return {
		type: ENABLE_PICK_UP_NEXT,
		payload: status
	}
};

export const toggleHotkeysHelper = t => {
	return {
		type: TOGGLE_HOTKEYS_HELPER,
		payload: t
	}
};

export const storeCurrentHotkey = keys => {
	return {
		type: STORE_HOTKEYS_ACTIVE,
		payload: keys
	}
};

export const setHotkeyList = keys => {
	return {
		type: SETUP_HOTKEY_LIST,
		payload: keys
	}
};

export const setHotkeyActive = t => {
	return {
		type: SET_HOTKEY_ACTIVE,
		payload: t
	}
};

export const toggleActiveHotkeyDialog = t => {
	return {
		type: ACTIVE_HOTKEY_DIALOG,
		payload: t
	}
};

export const toggleErrandFavouriteFTADropdown = toggle => ({
	type: TOGGLE_ERRAND_FAV_FORWARD_TO_AREA,
	payload: toggle
})
export const toggleErrandFTADropdown = toggle => ({
	type: TOGGLE_ERRAND_FORWARD_TO_AREA,
	payload: toggle
})

export const SetNotificationReadByErrand = eid => (dispatch, getState) => {
	let notification = getState().app.notification
	, id = notification.msgIdByErrand[eid];
	if (id) {
		let msg = notification.messages[id];
		if (msg && !msg.read) {
			readNotification(id);
		}
	}
}

export const toggleShowAllNotification = show => {
	return {
		type: SHOW_ALL_NOTIFICATION,
		payload: show
	}
}

export const setNotificationTab = tab => {
	return {
		type: SET_NOTIFICATION_TAB,
		payload: tab
	}
}

export const toggleShowAnnouncement = show => {
	return {
		type: SHOW_ANNOUNCEMENT,
		payload: show
	}
}

export const showChatSourceConfig = show => {
	return {
		type: SHOW_CHAT_SOURCE_CONFIG,
		payload: show
	}
}
export const toggleChatSource = (serviceType, enable) => {
	return {
		type: TOGGLE_CHAT_SOURCE,
		payload: {
			serviceType: serviceType,
			enable: enable
		}
	}
}
export const updateChatSourceStatus = () => (dispatch, getState) => {
	let totalChannel = getTotalChatChannelsSelector(getState());
	let totalActiveChannel = getTotalChatChannelsActiveSelector((getState()));
	dispatch({
		type: UPDATE_CHAT_CONFIG_STATUS,
		payload: {totalChannel, totalActiveChannel}
	});
}

export const showPopupNotification = () => (dispatch, getState) => {
	let uiData = getState().app.notification.uiData
	let groupErrandNotification = uiData.pendingPopupMessage.filter(msg => (msg.msgType == NOTIFICATION_WITHOUT_KEEP));
	if (uiData.pendingPopupMessage.length > 0 &&
		(uiData.currentPopupMessage.length < 2 || groupErrandNotification.length > 0)) {
		dispatch({
			type: SHOW_POPUP_NOTIFICATION,
			payload: {
				timeout: 4000,
				currentTime: Date.now(),
			}
		})
		setTimeout(() => dispatch(hidePopupNotification(0)), 10000);
	}
}

export const hidePopupNotification = id => (dispatch, getState) => {
	dispatch({
		type: HIDE_POPUP_NOTIFICATION,
		payload: {id, currentTime: Date.now()}

	})
	dispatch(showPopupNotification());
};

export const addNewPopupNotification = (msg, type) => (dispatch, getState) => {
	let skipBubble = false;
	if(features.browserOsNotify == true) {
		notifyOS("Cention",getNotificationMessage(msg), 0);
		skipBubble = true;
	}
	// Skip to add blue bubble notification if disabled popup by user setting
	let wfSettings = getState().app.workflow.fetchWfSettings;
	if (!wfSettings.data["user.blue.bubble.notification"] &&
		getExtQueueType() === "") {
		return;
	}
	if(skipBubble == false) {
		dispatch({
			type: ADD_NEW_POPUP_NOTIFICATION,
			payload: {
				text: JSON.stringify(msg),
				msgType: NOTIFICATION_WITHOUT_KEEP,
				type: type
			}
		});
	}
	dispatch(showPopupNotification());
}
